import {
  FieldBrand,
  ItemID,
  ItemUUID,
  ItemCreatedAt,
  MayBe,
  ItemIDs,
  ItemUpdatedAt
} from '../../../types';
import {
  JobCandidateFirstName,
  JobCandidateId,
  JobCandidateJobTitle,
  JobCandidateLastName,
  JobCandidateName,
  JobCandidateNanoId,
  MayBeJobCandidateCities,
  MayBeJobCandidateCity
} from '../../jobCandidates/jobCandidatesTypes';
import {
  JobDepartment,
  JobId,
  JobJobTitle,
  JobName,
  JobNanoId,
  MayBeJobCities,
  MayBeJobCity
} from '../../jobs/jobsTypes';

export type JobJobCandidateId = FieldBrand<ItemID, 'JobJobCandidateId'>;
export type JobJobCandidateIds = FieldBrand<ItemIDs, 'JobJobCandidateIds'>;
export type JobJobCandidateUuid = FieldBrand<ItemUUID, 'JobJobCandidateUuid'>;
export type MayBeJobJobCandidateUuid = MayBe<JobJobCandidateUuid>;
export type JobJobCandidateCreatedAt = FieldBrand<
  ItemCreatedAt,
  'JobJobCandidateCreatedAt'
>;
export type JobJobCandidateUpdatedAt = FieldBrand<
  ItemUpdatedAt,
  'JobJobCandidateUpdatedAt'
>;
export type JobJobCandidateJobId = FieldBrand<JobId, 'JobJobCandidateJobId'>;
export type JobJobCandidateJob = FieldBrand<
  {
    id: JobId;
    nanoId: JobNanoId;
    name: JobName;
    jobTitle: JobJobTitle;
    department: JobDepartment;
    city: MayBeJobCity;
    cities: MayBeJobCities;
  },
  'JobJobCandidateJob'
>;
export type JobJobCandidateJobCandidateId = FieldBrand<
  JobCandidateId,
  'JobJobCandidateJobCandidateId'
>;
export type JobJobCandidateJobCandidate = FieldBrand<
  {
    id: JobCandidateId;
    nanoId: JobCandidateNanoId;
    name: JobCandidateName;
    firstName: JobCandidateFirstName;
    lastName: JobCandidateLastName;
    jobTitle: JobCandidateJobTitle;
    city: MayBeJobCandidateCity;
    cities: MayBeJobCandidateCities;
  },
  'JobJobCandidateJobCandidate'
>;

export const enum JobJobCandidateFields {
  ID = 'id',
  UUID = 'uuid',
  JOB_ID = 'jobId',
  JOB = 'job',
  JOB_CANDIDATE_ID = 'jobCandidateId',
  JOB_CANDIDATE = 'jobCandidate',
  UPDATED_AT = 'updatedAt',
  CREATED_AT = 'createdAt'
}
