import { Flex, Stack, useDisclosure } from '@chakra-ui/react';
import join from 'lodash/join';
import map from 'lodash/map';
import startCase from 'lodash/startCase';
import { Heading } from '../../../../../../../../helpers/Heading';
import {
  CandidateSubmissionShareSettingsField,
  CandidateSubmissionShareSettingsFields
} from '../../../../../../../candidateSubmissions/candidateSubmissionsTypes';
import { useViewJobCandidateDetailsSubmissionContext } from '../../../../contexts/ViewJobCandidateDetailsSubmissionContext';
import {
  ViewJobCandidateDetailsCandidate,
  ViewJobCandidateDetailsJobType
} from '../../../../pages/ViewJobCandidateDetailsProfilePage/ViewJobCandidateDetailsProfilePage.types';
import { ViewJobCandidateDetailsCandidateUpdatedLabel } from '../../../ViewJobCandidateDetailsCandidateUpdatedLabel';
import { ViewJobCandidateDetailsProfileOverviewPreferencesItem } from './components/ViewJobCandidateDetailsProfileOverviewPreferencesItem';

interface ViewJobCandidateDetailsProfileOverviewPreferencesProps {
  jobCandidate: ViewJobCandidateDetailsCandidate;
  job: ViewJobCandidateDetailsJobType;
}

function ViewJobCandidateDetailsProfileOverviewPreferences({
  job,
  jobCandidate
}: ViewJobCandidateDetailsProfileOverviewPreferencesProps) {
  const { employmentTypes, workplaceTypes, cities } = jobCandidate;

  const {
    isOpen: isHovered,
    onOpen: onMouseHover,
    onClose: onMouseLeave
  } = useDisclosure();

  const { sharedFields } = useViewJobCandidateDetailsSubmissionContext();

  return (
    <Stack
      p={4}
      gap={3}
      flex={1}
      bg="white"
      borderRadius="base"
      onMouseOver={onMouseHover}
      onMouseLeave={onMouseLeave}
    >
      <Flex gap={2} alignItems="center">
        <Heading
          m={0}
          p={0}
          level="h4"
          fontSize="md"
          lineHeight={1}
          fontWeight="semibold"
        >
          Preferences
        </Heading>

        <ViewJobCandidateDetailsCandidateUpdatedLabel
          isHovered={isHovered}
          date="Yesterday"
        />
      </Flex>

      <Stack spacing={4} color="gray.600">
        <ViewJobCandidateDetailsProfileOverviewPreferencesItem
          label="Employment status"
          value={startCase(jobCandidate.employmentStatus)}
        />

        <ViewJobCandidateDetailsProfileOverviewPreferencesItem
          label="Job market status"
          value={startCase(jobCandidate.status)}
        />

        <ViewJobCandidateDetailsProfileOverviewPreferencesItem
          label="Work authorization"
          isMatch={
            (job?.workAuthorization as string) ===
            jobCandidate.workAuthorization
          }
          value={startCase(jobCandidate.workAuthorization)}
        />

        {sharedFields.includes(
          CandidateSubmissionShareSettingsFields.AssessmentPreferencesEmployment as CandidateSubmissionShareSettingsField
        ) && (
          <ViewJobCandidateDetailsProfileOverviewPreferencesItem
            label="Employment type"
            value={startCase(join(employmentTypes, ', '))}
          />
        )}

        {sharedFields.includes(
          CandidateSubmissionShareSettingsFields.AssessmentPreferencesPreferredLocation as CandidateSubmissionShareSettingsField
        ) && (
          <ViewJobCandidateDetailsProfileOverviewPreferencesItem
            label="Preferred locations"
            value={join(map(cities, 'label'), ' / ')}
          />
        )}

        {sharedFields.includes(
          CandidateSubmissionShareSettingsFields.AssessmentPreferencesWorkplace as CandidateSubmissionShareSettingsField
        ) && (
          <ViewJobCandidateDetailsProfileOverviewPreferencesItem
            label="Workplace type"
            value={startCase(join(workplaceTypes, ', '))}
          />
        )}

        <ViewJobCandidateDetailsProfileOverviewPreferencesItem
          label="Phone call availability"
          value={startCase(jobCandidate.phoneCallAvailability)}
        />
      </Stack>
    </Stack>
  );
}

export default ViewJobCandidateDetailsProfileOverviewPreferences;
