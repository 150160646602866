import { ReactNode, useCallback, useEffect } from 'react';
import { SystemProps, SystemStyleObject } from '@chakra-ui/react';
import debounce from 'lodash/debounce';
import find from 'lodash/find';
import uniqBy from 'lodash/uniqBy';

import {
  ErrorMessage,
  FilterOperator,
  IsDisabled,
  IsRequired
} from '../../../../../../../types';

import {
  JobFunctionFields,
  JobFunctionId,
  JobFunctionName,
  FetchJobFunctionsFilters,
  FetchJobFunctionsSort,
  FetchJobFunctionsCacheKey
} from '../../../../../../jobFunctions/jobFunctionsTypes';
import { DisciplineId } from '../../../../../../disciplines/disciplinesTypes';
import { JobTitleId } from '../../../../../../jobTitles/jobTitlesTypes';

import {
  fetchJobFunctionsQuery,
  FetchJobFunctionsResponse
} from '../../../../../../jobFunctions/queries/fetchJobFunctions.query';

import { usePaginatedJobFunctions } from '../../../../../../jobFunctions/hooks/usePaginatedJobFunctions';

import { JobFunctionsCache } from '../../../../../../jobFunctions/JobFunctionsCache';

import { useUpdateJobTitle } from '../../../../../../jobTitles/hooks/useUpdateJobTitle';
import { JobTitlesCache } from '../../../../../../jobTitles/JobTitlesCache';
import { useChakraToast } from '../../../../../../../helpers/useChakraToast';
import { JobJobFunctionId } from '../../../../../../jobs/jobsTypes';
import { SelectFieldControl } from '../../../../../../../helpers/forms/formFields/SelectField/components/SelectFieldControl';

export type SelectJobFunctionFormFieldControlWithAssociatedJobTitleProps = {
  selectedFunctionId: JobFunctionId;
  selectedJobTitleId: JobTitleId;
  selectedDisciplineId: DisciplineId;
  selectDisciplineId: (disciplineId: DisciplineId) => void;
};

export type SelectJobFunctionFormFieldControlWithoutAssociatedJobTitleProps = {
  selectedFunctionId?: never;
  selectedJobTitleId?: never;
  selectDisciplineId?: never;
  selectedDisciplineId?: never;
};

export type SelectJobFunctionFormFieldControlRequiredProps = {
  isRequired?: IsRequired;
  isDisabled?: IsDisabled;
  name: string;
  item?: {
    jobFunction: {
      id: JobFunctionId;
      name: JobFunctionName;
    } | null;
  } | null;
  errorMessage: ErrorMessage;
  onChange: (value: string | number | null) => void;
  labelAddon?: ReactNode;
  label?: string;
  placeholder?: string;
  reactSelectStyles?: Record<string, unknown>;
  isBorderless?: boolean;
  visibility?: SystemProps['visibility'];
  withoutUpdateJobTitle?: boolean;
  withoutLabel?: boolean;
  cacheKey?: FetchJobFunctionsCacheKey;
  unsetValue?: boolean;
  initialFilters?: {
    [field: string]: {
      operator: FilterOperator;
      value: string | string[] | number;
    };
  };
  withoutIntialJobFunctionFilter?: boolean;
  withAllJobFunctionsOption?: boolean;
} & (
  | SelectJobFunctionFormFieldControlWithAssociatedJobTitleProps
  | SelectJobFunctionFormFieldControlWithoutAssociatedJobTitleProps
);

function SelectJobFunctionFormFieldControl({
  isRequired,
  isDisabled,
  name,
  item,
  errorMessage,
  labelAddon,
  label,
  onChange,
  placeholder,
  selectedFunctionId,
  selectedJobTitleId,
  selectDisciplineId,
  selectedDisciplineId,
  reactSelectStyles,
  isBorderless,
  visibility,
  withoutLabel,
  withoutUpdateJobTitle,
  cacheKey,
  unsetValue,
  initialFilters,
  withAllJobFunctionsOption,
  withoutIntialJobFunctionFilter
}: SelectJobFunctionFormFieldControlRequiredProps) {
  const {
    jobFunctions,
    jobFunctionsIsLoading,
    jobFunctionsErrorMessage,
    changeJobFunctionsFilters
  } = usePaginatedJobFunctions<FetchJobFunctionsResponse>({
    query: fetchJobFunctionsQuery,
    cacheKey: cacheKey || JobFunctionsCache.indexCacheKey(),
    initialFilters: {
      ...(initialFilters || {}),
      ...(item?.jobFunction?.name && !withoutIntialJobFunctionFilter
        ? {
            [JobFunctionFields.NAME]: {
              operator: 'ilike',
              value: `${item?.jobFunction?.name}`
            }
          }
        : {})
    } as unknown as FetchJobFunctionsFilters,
    initialSort: {
      [JobFunctionFields.NAME]: { ascending: true }
    } as unknown as FetchJobFunctionsSort
  });

  const {
    updateJobTitle,
    updateJobTitleData,
    updateJobTitleIsLoading,
    updateJobTitleErrorMessage
  } = useUpdateJobTitle({
    jobTitleId: selectedJobTitleId as JobTitleId,
    cacheKeys: [JobTitlesCache.indexCacheKey()]
  });

  const toast = useChakraToast();

  const defaultItemUserJobFunction = item?.jobFunction
    ? { value: item.jobFunction.id, label: item.jobFunction.name }
    : withAllJobFunctionsOption
    ? { value: 'all', label: 'All Job Functions' }
    : undefined;

  const debouncedFilterJobFunctions = debounce<(updatedValue: string) => void>(
    (updatedValue) =>
      changeJobFunctionsFilters({
        [JobFunctionFields.NAME]: {
          operator: 'ilike',
          value: `${updatedValue}%`
        }
      }),
    500
  );

  const uniqueJobFunctions = uniqBy(jobFunctions, 'id');

  const handleUpdateJobTitle = useCallback(() => {
    if (!updateJobTitleData && !updateJobTitleIsLoading) {
      updateJobTitle({
        jobFunctionId: selectedFunctionId as JobJobFunctionId
      });
    }
  }, [
    updateJobTitle,
    updateJobTitleData,
    updateJobTitleIsLoading,
    selectedFunctionId
  ]);

  useEffect(() => {
    if (selectedFunctionId) {
      const selectedJobFunction = find(
        jobFunctions,
        ({ id }) => id === selectedFunctionId
      );

      if (
        selectedJobFunction &&
        selectedJobFunction?.discipline?.id !== selectedDisciplineId
      ) {
        !withoutUpdateJobTitle && handleUpdateJobTitle();
        selectDisciplineId(selectedJobFunction.discipline?.id);
      }
    }
  }, [
    selectedFunctionId,
    jobFunctions,
    selectDisciplineId,
    handleUpdateJobTitle,
    selectedDisciplineId,
    withoutUpdateJobTitle
  ]);

  useEffect(() => {
    updateJobTitleErrorMessage &&
      toast({ title: updateJobTitleErrorMessage, status: 'error' });
  }, [updateJobTitleErrorMessage, toast]);

  return (
    <SelectFieldControl
      isRequired={isRequired}
      isDisabled={isDisabled}
      label={withoutLabel ? undefined : label || 'Job Function'}
      labelAddon={labelAddon}
      placeholder={placeholder || 'Select job function'}
      name={name}
      options={[
        ...(withAllJobFunctionsOption
          ? [{ value: 'all', label: 'All Job Functions' }]
          : []),
        ...uniqueJobFunctions.map((jobFunction) => ({
          value: jobFunction.id,
          label: jobFunction.name
        }))
      ]}
      onChange={onChange}
      defaultValue={defaultItemUserJobFunction}
      errorMessage={errorMessage || jobFunctionsErrorMessage}
      isLoading={jobFunctionsIsLoading}
      onInputChange={debouncedFilterJobFunctions}
      reactSelectStyles={{
        ...(isBorderless
          ? {
              control: (base: SystemStyleObject) => ({
                ...base,
                borderColor: 'transparent',
                p: 0
              })
            }
          : {}),
        ...(reactSelectStyles || {})
      }}
      visibility={visibility}
      unsetValue={unsetValue}
    />
  );
}

export default SelectJobFunctionFormFieldControl;
