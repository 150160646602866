import snakeCase from 'lodash/snakeCase';

import {
  FetchJobCandidateQuery,
  JobCandidateFields,
  JobCandidateId,
  JobCandidateNanoId,
  JobCandidateName,
  JobCandidateEmail,
  JobCandidatePhone,
  JobCandidateJobTitle,
  JobCandidatePersonalPhone,
  JobCandidatePersonalEmail,
  JobCandidateCompanyPhone,
  JobCandidateCompanyEmail,
  JobCandidateLinkedinUrl,
  JobCandidateJobId,
  JobCandidateJob,
  JobCandidateStatus,
  MayBeJobCandidateImageId,
  MayBeJobCandidateImage,
  MayBeJobCandidateCompanyId,
  MayBeJobCandidateCompany,
  MayBeJobCandidateCityId,
  MayBeJobCandidateCity,
  JobCandidateState,
  JobCandidateJobResume,
  JobCandidateSkillBadges,
  JobCandidateJobCandidateEmployments,
  JobCandidateJobCandidateCertifications,
  JobCandidateJobCandidateEducations,
  JobCandidateCreatedAt,
  JobCandidateWorkflowStage,
  JobCandidateSearchType,
  JobCandidateSearchTypeLabel,
  JobCandidateUserId,
  MayBeJobCandidateDueDate,
  MayBeJobCandidateDisqualifiedAt,
  JobCandidateDisqualificationReason,
  MayBeJobCandidateWorkflowStageTaskScheduledAt,
  MayBeJobCandidateNewWorkflowStageTaskAt,
  MayBeJobCandidateViewedAt,
  JobCandidateWorkflowStageTaskApplicationType,
  JobCandidateWorkflowStageTask,
  JobCandidateDescription,
  JobCandidateProfessionalExperience,
  JobCandidateEmploymentTypes,
  JobCandidateWorkplaceTypes,
  JobCandidatePaidTimeOff,
  JobCandidatePrimaryJobFunction,
  JobCandidateWorkAuthorization,
  JobCandidateFutureRequirements,
  JobCandidateIndustryIds,
  JobCandidateIndustries,
  JobCandidateSpecializationIds,
  JobCandidateSpecializations,
  JobCandidateMinimumDesiredPay,
  JobCandidateMaximumDesiredPay,
  MayBeJobCandidateCities,
  MayBeJobCandidateCityIds,
  JobCandidateFirstName,
  JobCandidateLastName,
  JobCandidateLikedJobCandidateIds,
  JobCandidateLikedJobCandidates,
  JobCandidateCandidateShareIds,
  JobCandidateCandidateShares,
  JobCandidateCandidateSubmissions,
  JobCandidateUpdatedAt,
  JobCandidateEmploymentStatus,
  JobCandidatePhoneCallAvailability,
  JobCandidateNewJobStartAvailability,
  JobCandidateEmployerCounterOffer,
  JobCandidateActivelyInterviewing,
  JobCandidateCounterOffer
} from '../jobCandidatesTypes';

export interface FetchJobCandidateResponse {
  [JobCandidateFields.ID]: JobCandidateId;
  [JobCandidateFields.USER_ID]: JobCandidateUserId;
  [JobCandidateFields.NANO_ID]: JobCandidateNanoId;
  [JobCandidateFields.NAME]: JobCandidateName;
  [JobCandidateFields.FIRST_NAME]: JobCandidateFirstName;
  [JobCandidateFields.LAST_NAME]: JobCandidateLastName;
  [JobCandidateFields.EMAIL]: JobCandidateEmail;
  [JobCandidateFields.PHONE]: JobCandidatePhone;
  [JobCandidateFields.JOB_TITLE]: JobCandidateJobTitle;
  [JobCandidateFields.PERSONAL_EMAIL]: JobCandidatePersonalEmail;
  [JobCandidateFields.PERSONAL_PHONE]: JobCandidatePersonalPhone;
  [JobCandidateFields.COMPANY_PHONE]: JobCandidateCompanyPhone;
  [JobCandidateFields.COMPANY_EMAIL]: JobCandidateCompanyEmail;
  [JobCandidateFields.LINKEDIN_URL]: JobCandidateLinkedinUrl;
  [JobCandidateFields.STATUS]: JobCandidateStatus;
  [JobCandidateFields.JOB_ID]: JobCandidateJobId;
  [JobCandidateFields.JOB]: JobCandidateJob;
  [JobCandidateFields.IMAGE_ID]: MayBeJobCandidateImageId;
  [JobCandidateFields.IMAGE]: MayBeJobCandidateImage;
  [JobCandidateFields.COMPANY_ID]: MayBeJobCandidateCompanyId;
  [JobCandidateFields.COMPANY]: MayBeJobCandidateCompany;
  [JobCandidateFields.CITY_ID]: MayBeJobCandidateCityId;
  [JobCandidateFields.CITY]: MayBeJobCandidateCity;
  [JobCandidateFields.STATE]: JobCandidateState;
  [JobCandidateFields.JOB_RESUME]: JobCandidateJobResume;
  [JobCandidateFields.SKILL_BADGES]: JobCandidateSkillBadges;
  [JobCandidateFields.JOB_CANDIDATE_EMPLOYMENTS]: JobCandidateJobCandidateEmployments;
  [JobCandidateFields.JOB_CANDIDATE_CERTIFICATIONS]: JobCandidateJobCandidateCertifications;
  [JobCandidateFields.JOB_CANDIDATE_EDUCATIONS]: JobCandidateJobCandidateEducations;
  [JobCandidateFields.CREATED_AT]: JobCandidateCreatedAt;
  [JobCandidateFields.UPDATED_AT]: JobCandidateUpdatedAt;
  [JobCandidateFields.WORKFLOW_STAGE]: JobCandidateWorkflowStage;
  [JobCandidateFields.SEARCH_TYPE]: JobCandidateSearchType;
  [JobCandidateFields.SEARCH_TYPE_LABEL]: JobCandidateSearchTypeLabel;
  [JobCandidateFields.WORKFLOW_STAGE_TASK]: JobCandidateWorkflowStageTask;
  [JobCandidateFields.DISQUALIFIED_AT]: MayBeJobCandidateDisqualifiedAt;
  [JobCandidateFields.DISQUALIFICATION_REASON]: JobCandidateDisqualificationReason;
  [JobCandidateFields.WORKFLOW_STAGE_TASK_SCHEDULED_AT]: MayBeJobCandidateWorkflowStageTaskScheduledAt;
  [JobCandidateFields.NEW_WORKFLOW_STAGE_TASK_AT]: MayBeJobCandidateNewWorkflowStageTaskAt;
  [JobCandidateFields.VIEWED_AT]: MayBeJobCandidateViewedAt;
  [JobCandidateFields.WORKFLOW_STAGE_TASK_APPLICATION_TYPE]: JobCandidateWorkflowStageTaskApplicationType;
  [JobCandidateFields.DUE_DATE]: MayBeJobCandidateDueDate;
  [JobCandidateFields.DESCRIPTION]: JobCandidateDescription;
  [JobCandidateFields.PROFESSIONAL_EXPERIENCE]: JobCandidateProfessionalExperience;
  [JobCandidateFields.EMPLOYMENT_TYPES]: JobCandidateEmploymentTypes;
  [JobCandidateFields.WORKPLACE_TYPES]: JobCandidateWorkplaceTypes;
  [JobCandidateFields.PAID_TIME_OFF]: JobCandidatePaidTimeOff;
  [JobCandidateFields.PRIMARY_JOB_FUNCTION]: JobCandidatePrimaryJobFunction;
  [JobCandidateFields.WORK_AUTHORIZATION]: JobCandidateWorkAuthorization;
  [JobCandidateFields.FUTURE_REQUIREMENTS]: JobCandidateFutureRequirements;
  [JobCandidateFields.INDUSTRY_IDS]: JobCandidateIndustryIds;
  [JobCandidateFields.INDUSTRIES]: JobCandidateIndustries;
  [JobCandidateFields.SPECIALIZATION_IDS]: JobCandidateSpecializationIds;
  [JobCandidateFields.SPECIALIZATIONS]: JobCandidateSpecializations;
  [JobCandidateFields.MINIMUM_DESIRED_PAY]: JobCandidateMinimumDesiredPay;
  [JobCandidateFields.MAXIMUM_DESIRED_PAY]: JobCandidateMaximumDesiredPay;
  [JobCandidateFields.CITY_IDS]: MayBeJobCandidateCityIds;
  [JobCandidateFields.CITIES]: MayBeJobCandidateCities;
  [JobCandidateFields.LIKED_JOB_CANDIDATE_IDS]: JobCandidateLikedJobCandidateIds;
  [JobCandidateFields.LIKED_JOB_CANDIDATES]: JobCandidateLikedJobCandidates;
  [JobCandidateFields.CANDIDATE_SHARE_IDS]: JobCandidateCandidateShareIds;
  [JobCandidateFields.CANDIDATE_SHARES]: JobCandidateCandidateShares;
  [JobCandidateFields.CANDIDATE_SUBMISSIONS]: JobCandidateCandidateSubmissions;
  [JobCandidateFields.EMPLOYMENT_STATUS]: JobCandidateEmploymentStatus;
  [JobCandidateFields.PHONE_CALL_AVAILABILITY]: JobCandidatePhoneCallAvailability;
  [JobCandidateFields.NEW_JOB_START_AVAILABILITY]: JobCandidateNewJobStartAvailability;
  [JobCandidateFields.EMPLOYER_COUNTER_OFFER]: JobCandidateEmployerCounterOffer;
  [JobCandidateFields.ACTIVELY_INTERVIEWING]: JobCandidateActivelyInterviewing;
  [JobCandidateFields.COUNTER_OFFER]: JobCandidateCounterOffer;
}

export const fetchJobCandidateColumns = [
  JobCandidateFields.ID,
  JobCandidateFields.USER_ID,
  JobCandidateFields.NANO_ID,
  JobCandidateFields.NAME,
  JobCandidateFields.FIRST_NAME,
  JobCandidateFields.LAST_NAME,
  JobCandidateFields.EMAIL,
  JobCandidateFields.PHONE,
  JobCandidateFields.JOB_TITLE,
  JobCandidateFields.PERSONAL_EMAIL,
  JobCandidateFields.PERSONAL_PHONE,
  JobCandidateFields.COMPANY_PHONE,
  JobCandidateFields.COMPANY_EMAIL,
  JobCandidateFields.LINKEDIN_URL,
  JobCandidateFields.STATUS,
  JobCandidateFields.JOB_ID,
  JobCandidateFields.IMAGE_ID,
  JobCandidateFields.COMPANY_ID,
  JobCandidateFields.CITY_ID,
  JobCandidateFields.CREATED_AT,
  JobCandidateFields.SEARCH_TYPE,
  JobCandidateFields.SEARCH_TYPE_LABEL,
  JobCandidateFields.DISQUALIFIED_AT,
  JobCandidateFields.DISQUALIFICATION_REASON,
  JobCandidateFields.WORKFLOW_STAGE_TASK_SCHEDULED_AT,
  JobCandidateFields.NEW_WORKFLOW_STAGE_TASK_AT,
  JobCandidateFields.VIEWED_AT,
  JobCandidateFields.WORKFLOW_STAGE_TASK_APPLICATION_TYPE,
  JobCandidateFields.DUE_DATE,
  JobCandidateFields.DESCRIPTION,
  JobCandidateFields.PROFESSIONAL_EXPERIENCE,
  JobCandidateFields.EMPLOYMENT_TYPES,
  JobCandidateFields.WORKPLACE_TYPES,
  JobCandidateFields.PAID_TIME_OFF,
  JobCandidateFields.PRIMARY_JOB_FUNCTION,
  JobCandidateFields.WORK_AUTHORIZATION,
  JobCandidateFields.FUTURE_REQUIREMENTS,
  JobCandidateFields.MINIMUM_DESIRED_PAY,
  JobCandidateFields.MAXIMUM_DESIRED_PAY
];

const fetchJobCandidateSBColumns = fetchJobCandidateColumns.map(
  (fetchJobCandidateColumn) => snakeCase(fetchJobCandidateColumn)
);

export const fetchJobCandidateQuery = `
  ${fetchJobCandidateSBColumns.join(',')},
  state:state_id(id,nano_id,name,iso_code),
  city:city_id(id,nano_id,name,label)
  specialization_ids:specializations!job_candidate_specializations(id),
  specializations!job_candidate_specializations(id, name),
  industry_ids:industries!job_candidate_industries(id),
  industries!job_candidate_industries(id, name),
  image:image_id(id,nano_id,name,file,key,created_at),
  company:company_id(id,nano_id,name,created_at,image:image_id(id,nano_id,name,file,key,created_at)),
  job:job_id(id,nano_id,name,created_at),
  job_resume:job_resume_id(id,nano_id,created_at),
  skill_badges(id,nano_id,rating,experience,requirements,skill:skill_id(id,nano_id,name,kind),skill_license:skill_license_id(id,nano_id,name)),
  job_candidate_employments(id,nano_id,name,start_date,end_date,company:company_id(id,nano_id,name),state:state_id(id,nano_id,name,iso_code),city:city_id(id,nano_id,name,label)),
  job_candidate_certifications(id,nano_id,name,start_date,end_date,issue_date,company:company_id(id,nano_id,name)),
  job_candidate_educations(id,nano_id,degree,school_name,study_field,school_type,start_date,end_date,state:state_id(id,nano_id,name,iso_code),city:city_id(id,nano_id,name,label)),
  workflow_stage:workflow_stage_id(id,nano_id,name,color,arrangement,workflow_stage_tasks!fk_rails_bd60de3db2(id,nano_id,name,arrangement,application,due_date)),
  workflow_stage_task:workflow_stage_task_id(id,nano_id,name,arrangement,application,due_date)
` as FetchJobCandidateQuery;
