import {
  FetchJobJobCandidatesQuery,
  FetchJobJobCandidatesCountType,
  FetchJobJobCandidatesPage,
  FetchJobJobCandidatesPageSize,
  FetchJobJobCandidatesFilters,
  FetchJobJobCandidatesSort,
  FetchJobJobCandidatesIndexQueryName,
  FetchJobJobCandidatesIndexCountType,
  FetchJobJobCandidatesIndexPage,
  FetchJobJobCandidatesIndexPageSize,
  FetchJobJobCandidatesIndexFilters,
  FetchJobJobCandidatesIndexSort,
  FetchJobJobCandidateQuery,
  FetchJobJobCandidateIndexQueryName,
  CreateJobJobCandidateError,
  DeleteJobJobCandidateError,
  JobJobCandidateId,
  JobJobCandidateUuid
} from './jobJobCandidatesTypes';

import { BaseBffRequests } from '../BaseBffRequests';

import { JobJobCandidatesBffRoutes } from './JobJobCandidatesBffRoutes';
import { JobCandidateId } from '../jobCandidates/jobCandidatesTypes';
import { JobId } from '../jobs/jobsTypes';

interface FetchJobJobCandidatesRequestParams {
  query: FetchJobJobCandidatesQuery;
  countType?: FetchJobJobCandidatesCountType;
  page?: FetchJobJobCandidatesPage;
  pageSize?: FetchJobJobCandidatesPageSize;
  filters?: FetchJobJobCandidatesFilters;
  sort?: FetchJobJobCandidatesSort;
}

interface FetchJobJobCandidatesIndexRequestParams {
  queryName: FetchJobJobCandidatesIndexQueryName;
  countType?: FetchJobJobCandidatesIndexCountType;
  page?: FetchJobJobCandidatesIndexPage;
  pageSize?: FetchJobJobCandidatesIndexPageSize;
  filters?: FetchJobJobCandidatesIndexFilters;
  sort?: FetchJobJobCandidatesIndexSort;
}

interface FetchJobJobCandidateRequestParams {
  query: FetchJobJobCandidateQuery;
}

interface FetchJobJobCandidateIndexRequestParams {
  queryName: FetchJobJobCandidateIndexQueryName;
}

export interface JobJobCandidateRequestData {
  jobId: JobId;
  jobCandidateId: JobCandidateId;
}

export interface CreateJobJobCandidateRequestData
  extends JobJobCandidateRequestData {
  id?: JobJobCandidateId;
}

export interface CreateJobJobCandidateErrorResponse {
  message: CreateJobJobCandidateError;
}

export interface DeleteJobJobCandidateErrorResponse {
  message: DeleteJobJobCandidateError;
}

export class JobJobCandidatesBffRequests extends BaseBffRequests {
  static fetchJobJobCandidates<T>(params: FetchJobJobCandidatesRequestParams) {
    return this.fetchItems<T>(
      JobJobCandidatesBffRoutes.fetchJobJobCandidatesRoute(),
      params
    );
  }

  static fetchJobJobCandidatesIndex<T>(
    params: FetchJobJobCandidatesIndexRequestParams
  ) {
    return this.fetchItemsIndex<T>(
      JobJobCandidatesBffRoutes.fetchJobJobCandidatesIndexRoute(),
      params
    );
  }

  static fetchJobJobCandidate<T>(
    jobJobCandidateId: JobJobCandidateId,
    params: FetchJobJobCandidateRequestParams
  ) {
    return this.fetchItem<T>(
      JobJobCandidatesBffRoutes.fetchJobJobCandidateRoute(jobJobCandidateId),
      params
    );
  }

  static fetchJobJobCandidateIndex<T>(
    jobJobCandidateUuId: JobJobCandidateUuid,
    params: FetchJobJobCandidateIndexRequestParams
  ) {
    return this.fetchItemIndex<T>(
      JobJobCandidatesBffRoutes.fetchJobJobCandidateIndexRoute(
        jobJobCandidateUuId
      ),
      params
    );
  }

  static createJobJobCandidate<T>(
    createJobJobCandidateData: CreateJobJobCandidateRequestData
  ) {
    return this.createItem<T, CreateJobJobCandidateRequestData>(
      JobJobCandidatesBffRoutes.createJobJobCandidateRoute(),
      createJobJobCandidateData
    );
  }

  static async deleteJobJobCandidate<T>(
    deleteJobJobCandidateId: JobJobCandidateId
  ) {
    return this.deleteItem<T>(
      JobJobCandidatesBffRoutes.deleteJobJobCandidateRoute(
        deleteJobJobCandidateId
      )
    );
  }
}
