import React, { useEffect, useState } from 'react';
import { Box, Flex, Stack, StackDivider } from '@chakra-ui/layout';
import { Avatar, AvatarBadge } from '@chakra-ui/avatar';
import { SystemProps } from '@chakra-ui/system';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';

import { TextStyle } from '../../../../../../theme';

import { AvatarPlaceholderIcon } from '../../../../../../icons/AvatarPlaceholderIcon';

import { ImagesBffRequests } from '../../../../../../main/images/ImagesBffRequests';

import { AlertMessage } from '../../../../../AlertMessage';
import { Button } from '../../../../../Button';
import { Text } from '../../../../../Text';

import { ImagesUrl } from '../../../../../../main/images/ImagesUrl';

import { UploadImageFieldDefaultImage } from '../../UploadImageField.types';
import { UserImage } from '../../../../../../main/users/usersTypes';
import { AvatarHelper } from '../../../../../AvatarHelper';
import { SkeletonCircle } from '@chakra-ui/react';
import {
  FetchItemCacheKey,
  FetchItemIndexCacheKey,
  FetchItemsCacheKey,
  FetchItemsIndexCacheKey
} from '../../../../../../types';
import { useQueryClient } from 'react-query';

interface UploadImageFieldControlProps {
  id?: string;
  label?: string;
  size?: number | string;
  width?: number | string;
  height?: number | string;
  hasError?: boolean;
  errorMessage?: string;
  isLoading?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  showDefaultAvatars?: boolean;
  value: string | null;
  onChange: (value: string | null) => void;
  defaultImage?: UploadImageFieldDefaultImage | null;
  placeholder?: string;
  optionsDirection?: 'row' | 'column';
  stackDirection?: 'row' | 'column';
  optionsSpacing?: SystemProps['margin'];
  stackSpacing?: SystemProps['margin'];
  containerSpacing?: SystemProps['margin'];
  avatarBorderRadius?: SystemProps['borderRadius'];
  labelTextStyle?: TextStyle;
  labelTextAlign?: SystemProps['textAlign'];
  defaultAvatarLabel?: string;
  defaultAvatars?: UserImage[] | null;
  cacheKeys?: (
    | FetchItemsCacheKey
    | FetchItemsIndexCacheKey
    | FetchItemCacheKey
    | FetchItemIndexCacheKey
  )[];
}

function UploadImageFieldControl({
  label,
  defaultAvatars,
  showDefaultAvatars,
  errorMessage,
  onChange,
  defaultImage,
  size = 32,
  width,
  height,
  placeholder,
  isLoading: defaultIsLoading,
  optionsDirection = 'column',
  stackDirection = 'row',
  optionsSpacing = '4',
  stackSpacing = '6',
  containerSpacing = '4',
  avatarBorderRadius = 'full',
  labelTextStyle = 'body1Medium',
  labelTextAlign = 'left',
  defaultAvatarLabel = 'Or choose one of our defaults',
  cacheKeys
}: UploadImageFieldControlProps) {
  const queryClient = useQueryClient();
  const [imageUrl, setImageUrl] = useState<string | null>(
    defaultImage ? ImagesUrl.file(defaultImage) : null
  );
  const [imageErrorMessage, setErrorMessage] = useState<string | null>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onDrop = (acceptedFiles: File[]) => {
    const fileUploads = acceptedFiles.map(async (acceptedFile) => {
      setErrorMessage(null);
      setIsLoading(false);

      if (!acceptedFile) {
        setImageUrl(null);
        setIsLoading(false);
        return;
      }

      const objectUrl = URL.createObjectURL(acceptedFile);

      setImageUrl(objectUrl);

      try {
        setIsLoading(true);

        const { id, presignedUrl } = await ImagesBffRequests.createImage({
          fileName: acceptedFile.name,
          name: acceptedFile.name,
          contentType: acceptedFile.type,
          fileSize: acceptedFile.size
        });

        await axios.put(presignedUrl, acceptedFile, {
          headers: {
            'Content-Type': acceptedFile.type,
            'x-amz-acl': 'public-read'
          }
        });

        cacheKeys?.map((cacheKey) => queryClient.invalidateQueries(cacheKey));

        setIsLoading(false);

        onChange(id);
      } catch (err) {
        setErrorMessage('Failed image upload');
        setIsLoading(false);
      }
    });

    return Promise.all(fileUploads);
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop
  });

  useEffect(() => {
    defaultImage && setImageUrl(ImagesUrl.file(defaultImage));
  }, [defaultImage, defaultIsLoading]);

  return (
    <Stack spacing={containerSpacing}>
      {label && (
        <Text textStyle={labelTextStyle} textAlign={labelTextAlign}>
          {label}
        </Text>
      )}

      <Stack direction={stackDirection} spacing={stackSpacing}>
        <Box as="label" htmlFor="upload-avatar" cursor="pointer">
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            {imageUrl ? (
              <Avatar
                src={imageUrl}
                w={width || size}
                h={height || size}
                borderRadius={avatarBorderRadius}
              >
                {defaultIsLoading || isLoading ? (
                  <AvatarBadge boxSize="1.25em" bg="gray.300" />
                ) : null}
                {imageErrorMessage ? (
                  <AvatarBadge boxSize="1.25em" bg="red.500" />
                ) : null}
              </Avatar>
            ) : (
              <>
                {defaultIsLoading || isLoading ? (
                  <SkeletonCircle
                    w={width || size}
                    h={height || size}
                    borderRadius={avatarBorderRadius}
                  />
                ) : (
                  <AvatarPlaceholderIcon
                    w={width || size}
                    h={height || size}
                    borderRadius={avatarBorderRadius}
                    className="form-input-class"
                  />
                )}
              </>
            )}
          </div>
        </Box>

        <Stack
          direction={optionsDirection}
          spacing={optionsSpacing}
          justifyContent="center"
          alignItems={optionsDirection === 'row' ? 'center' : 'start'}
          divider={
            optionsDirection === 'row' ? (
              <StackDivider h={6} alignSelf="center" />
            ) : undefined
          }
        >
          <Box width={stackDirection === 'column' ? '100%' : undefined}>
            <Box as="label" htmlFor="upload-avatar">
              <Button
                as="section"
                size="small"
                width="100%"
                hierarchy="secondary"
                cursor="pointer"
                onClick={open}
              >
                {placeholder ? placeholder : 'Choose image'}
              </Button>
            </Box>
          </Box>

          {defaultAvatars && showDefaultAvatars ? (
            <Stack spacing={4} direction="column">
              <Text textStyle="body1Regular" color="gray.600">
                {defaultAvatarLabel}
              </Text>

              <Flex gap={4}>
                {defaultAvatars.map((image, index) => (
                  <Button hierarchy="unstyled" size="medium" key={index}>
                    <AvatarHelper w={10} h={10} image={image} />
                  </Button>
                ))}
              </Flex>
            </Stack>
          ) : null}
        </Stack>
      </Stack>

      <AlertMessage message={imageErrorMessage || errorMessage} />
    </Stack>
  );
}

export default UploadImageFieldControl;
