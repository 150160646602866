import {
  FieldBrand,
  ItemID,
  ItemCreatedAt,
  ItemUUID,
  MayBe
} from '../../../types';

import { JobCandidateId } from '../../jobCandidates/jobCandidatesTypes';
import { JobId } from '../../jobs/jobsTypes';
import {
  UserFirstName,
  UserFullName,
  UserId,
  UserImage,
  UserLastName,
  UserName
} from '../../users/usersTypes';

export type LikedJobCandidateId = FieldBrand<ItemID, 'LikedJobCandidateId'>;
export type LikedJobCandidateUUID = FieldBrand<
  ItemUUID,
  'LikedJobCandidateUUID'
>;
export type MayBeLikedJobCandidateUUID = MayBe<LikedJobCandidateUUID>;
export type LikedJobCandidateJobCandidateId = FieldBrand<
  JobCandidateId,
  'LikedJobCandidateJobCandidateId'
>;
export type LikedJobCandidateUserId = FieldBrand<
  UserId,
  'LikedJobCandidateUserId'
>;
export type LikedJobCandidateUser = FieldBrand<
  {
    id: UserId;
    name: UserName;
    firstName: UserFirstName;
    lastName: UserLastName;
    fullName: UserFullName;
    image: UserImage;
  },
  'LikedJobCandidateUser'
>;
export type LikedJobCandidateCreatedAt = FieldBrand<
  ItemCreatedAt,
  'LikedJobCandidateCreatedAt'
>;

export const enum LikedJobCandidateFields {
  ID = 'id',
  USER_ID = 'userId',
  USER = 'user',
  JOB_CANDIDATE_ID = 'jobCandidateId',
  CREATED_AT = 'createdAt'
}
