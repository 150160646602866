import { Box, Flex, Grid, GridItem, useDisclosure } from '@chakra-ui/react';
import { Fragment, ReactNode, useEffect, useRef, useState } from 'react';
import { useCurrentUser } from '../../../../../../auth/hooks/useAuth';
import { useScrollPosition } from '../../../../../../common/hooks/utils/useScrollPosition';
import { AlertMessage } from '../../../../../../helpers/AlertMessage';
import { FullPageLoader } from '../../../../../../helpers/FullPageLoader';
import { LogoIconLoop } from '../../../../../../helpers/Lotties/LogoIconLoop';
import { ErrorMessage, IsFetched, IsLoading } from '../../../../../../types';
import {
  DepartmentId,
  DepartmentNanoId
} from '../../../../../departments/departmentsTypes';
import { JobCandidateNanoId } from '../../../../../jobCandidates/jobCandidatesTypes';
import { useFetchJobByNanoId } from '../../../../../jobs/hooks/useFetchJobByNanoId';
import { JobsCache } from '../../../../../jobs/JobsCache';
import { FetchJobEnabled } from '../../../../../jobs/jobsTypes';
import {
  ViewJobCandidateDetailsCandidateDetails,
  ViewJobCandidateDetailsCandidateDetailsJobCandidate
} from '../../../../../jobs/pages/ViewJobCandidateDetailsPage/components/ViewJobCandidateDetailsCandidateDetails';
import { ViewJobCandidateDetailsFloatingStageTasksManager } from '../../../../../jobs/pages/ViewJobCandidateDetailsPage/components/ViewJobCandidateDetailsFloatingStageTasksManager';
import { ViewJobCandidateDetailsJobDetailsJob } from '../../../../../jobs/pages/ViewJobCandidateDetailsPage/components/ViewJobCandidateDetailsJobDetails';
import { ViewJobCandidateDetailsSendAssessmentSidebar } from '../../../../../jobs/pages/ViewJobCandidateDetailsPage/components/ViewJobCandidateDetailsSendAssessmentSidebar';
import { ViewJobCandidateDetailsSubmissionSidebar } from '../../../../../jobs/pages/ViewJobCandidateDetailsPage/components/ViewJobCandidateDetailsSubmissionSidebar';
import { useViewJobCandidateDetailsSendAssessmentContext } from '../../../../../jobs/pages/ViewJobCandidateDetailsPage/contexts/ViewJobCandidateDetailsSendAssessmentContext';
import { useViewJobCandidateDetailsSubmissionContext } from '../../../../../jobs/pages/ViewJobCandidateDetailsPage/contexts/ViewJobCandidateDetailsSubmissionContext';
import {
  fetchJobQuery,
  FetchJobResponse
} from '../../../../../jobs/queries/fetchJob.query';
import { TalentPoolCandidatesListContainer } from '../../../../components/helpers/TalentPoolCandidatesListContainer';
import { SourcingRoutes } from '../../../../SourcingRoutes';
import { SourcingTalentProfileHeaderMenu } from '../SourcingTalentProfileHeaderMenu';
import { SourcingTalentProfileJobCandidateHeader } from '../SourcingTalentProfileJobCandidateHeader';

interface SourcingTalentProfilePageContentProps {
  departmentNanoId: DepartmentNanoId;
  personNanoId: JobCandidateNanoId;
  children?: ReactNode;
  isBasePath?: boolean;
  requiresCredit: boolean;
  departmentErrorMessage: ErrorMessage;
  department: {
    nanoId: DepartmentNanoId;
    id: DepartmentId;
  };
  jobCandidateIsFetched: IsFetched;
  jobCandidateIsLoading: IsLoading;
  jobCandidateErrorMessage: ErrorMessage;
  jobCandidate: ViewJobCandidateDetailsCandidateDetailsJobCandidate;
  isJobDetailsPage?: boolean;
}

function SourcingTalentProfilePageContent({
  children,
  departmentNanoId,
  personNanoId,
  isBasePath,
  requiresCredit,
  department,
  departmentErrorMessage,
  jobCandidateIsLoading,
  jobCandidate,
  jobCandidateErrorMessage,
  isJobDetailsPage
}: SourcingTalentProfilePageContentProps) {
  const currentUser = useCurrentUser();
  const topScrollRef = useRef<HTMLDivElement>(null);

  const { job, jobIsFetched, jobIsLoading } =
    useFetchJobByNanoId<FetchJobResponse>({
      jobNanoId: jobCandidate?.job?.nanoId,
      query: fetchJobQuery,
      enabled: !!jobCandidate?.job?.nanoId as FetchJobEnabled,
      cacheKey: JobsCache.showJobCacheKey(jobCandidate?.job?.nanoId)
    });

  const [totalProspects, setTotalProspects] = useState<number | undefined>();

  const {
    isOpen: isBioOutsideViewport,
    onOpen: setBioOutsideViewport,
    onClose: setBioInsideViewport
  } = useDisclosure();

  const bioContainerRef = useScrollPosition({
    setIsInsideViewport: setBioInsideViewport,
    setIsOutsideViewport: setBioOutsideViewport
  });

  const { isCandidateSubmissionOpen } =
    useViewJobCandidateDetailsSubmissionContext();

  const { isSendEmailAssessmentOpen } =
    useViewJobCandidateDetailsSendAssessmentContext();

  const isRightSidebarOpen =
    isCandidateSubmissionOpen || isSendEmailAssessmentOpen;

  useEffect(() => {
    if (isBasePath) {
      setBioInsideViewport();

      if (topScrollRef.current) {
        topScrollRef.current.scrollIntoView({
          block: 'start'
        });
      }
    }
  }, [isBasePath, jobCandidate, setBioInsideViewport]);

  useEffect(() => {
    isRightSidebarOpen &&
      topScrollRef.current &&
      topScrollRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
  }, [isRightSidebarOpen]);

  return (
    <Fragment>
      <Box
        minH="100%"
        pos="relative"
        transitionDuration="fast"
        w={isRightSidebarOpen ? 'calc(100% - 400px)' : '100%'}
      >
        <Box
          h={10}
          w="full"
          opacity={0}
          pos="absolute"
          ref={topScrollRef}
          pointerEvents="none"
          cursor="not-allowed"
          zIndex={999}
        />

        {(jobCandidateIsLoading || jobIsLoading) && (
          <Flex
            pos="fixed"
            h={{
              base: 'calc(100vh - 64px)',
              lg: '100vh'
            }}
            zIndex={99}
            bg="red.300"
            w={{
              base: isRightSidebarOpen ? '100%' : 'calc(100% - 290px)',
              xl: isRightSidebarOpen ? '100%' : 'calc(100% - 380px)'
            }}
            left={{
              base: isRightSidebarOpen ? '0' : '290px',
              xl: isRightSidebarOpen ? '0' : '380px'
            }}
          >
            <FullPageLoader background="white" loader={<LogoIconLoop />} />
          </Flex>
        )}

        <Flex minH="100%" flexDir="column">
          <SourcingTalentProfileHeaderMenu
            hasScrolled={isJobDetailsPage || isBioOutsideViewport}
            requiresCredit={requiresCredit}
            jobCandidate={jobCandidate}
            job={job as ViewJobCandidateDetailsJobDetailsJob}
            jobIsFetched={jobIsFetched}
            totalProspects={totalProspects}
            departmentNanoId={departmentNanoId}
          />

          <Grid
            gap={2}
            templateColumns={{
              base: isRightSidebarOpen ? '1fr' : '290px 1fr',
              xl: isRightSidebarOpen ? '1fr' : '320px 1fr'
            }}
          >
            {jobCandidateErrorMessage || departmentErrorMessage ? (
              <GridItem colSpan={2}>
                <AlertMessage
                  message={jobCandidateErrorMessage || departmentErrorMessage}
                />
              </GridItem>
            ) : null}

            {isRightSidebarOpen ? null : (
              <TalentPoolCandidatesListContainer
                personNanoId={personNanoId}
                department={department}
                currentUser={currentUser}
                jobCandidateRoute={SourcingRoutes.viewPerson}
                updateProspectsCount={setTotalProspects}
              />
            )}

            {jobCandidate ? (
              <Box
                pl={isRightSidebarOpen ? { base: 4, xl: 12 } : undefined}
                pr={isRightSidebarOpen ? { base: 4, xl: 6 } : undefined}
                pb={isRightSidebarOpen ? 24 : 4}
                pos="relative"
                transform="auto"
              >
                <Box
                  pos="relative"
                  transform="auto"
                  transitionDuration="fast"
                  bg={isRightSidebarOpen ? 'primary.50' : 'transparent'}
                  borderRadius={isRightSidebarOpen ? 'base' : undefined}
                  border={isRightSidebarOpen ? '1px solid' : undefined}
                  borderColor={
                    isRightSidebarOpen ? 'primary.200' : 'transparent'
                  }
                >
                  {isRightSidebarOpen && (
                    <Box
                      w="full"
                      h="full"
                      opacity={0}
                      pos="absolute"
                      cursor="not-allowed"
                      zIndex={999}
                    />
                  )}

                  <Box
                    transitionDuration="fast"
                    px={isRightSidebarOpen ? 6 : 0}
                    pb={isRightSidebarOpen ? 6 : 0}
                    pos="relative"
                    transform="auto"
                  >
                    {isJobDetailsPage ? null : (
                      <Box py={4}>
                        <SourcingTalentProfileJobCandidateHeader
                          jobCandidate={jobCandidate}
                        />
                      </Box>
                    )}

                    <Box
                      h={6}
                      top={20}
                      zIndex={99}
                      opacity={0}
                      pos="absolute"
                      pointerEvents="none"
                      ref={bioContainerRef}
                      w="full"
                    />

                    <ViewJobCandidateDetailsCandidateDetails
                      job={jobCandidate.job || job}
                      isBasePath={isBasePath}
                      isHiringPortalPath={false}
                      prospect
                      jobCandidate={jobCandidate}
                      requiresCredit={requiresCredit}
                      departmentNanoId={departmentNanoId}
                      isJobDetailsPage={isJobDetailsPage}
                    >
                      {children}
                    </ViewJobCandidateDetailsCandidateDetails>
                  </Box>
                </Box>
              </Box>
            ) : null}
          </Grid>
        </Flex>

        {job && jobCandidate ? (
          <ViewJobCandidateDetailsFloatingStageTasksManager
            hasScrolled={isBioOutsideViewport}
            jobCandidateNanoId={jobCandidate?.nanoId}
            jobCandidate={jobCandidate}
            job={job}
          />
        ) : null}
      </Box>

      {job && (
        <>
          {isRightSidebarOpen ? (
            <>
              {isCandidateSubmissionOpen && (
                <ViewJobCandidateDetailsSubmissionSidebar
                  job={job}
                  jobCandidateNanoId={personNanoId}
                />
              )}

              {isSendEmailAssessmentOpen && (
                <ViewJobCandidateDetailsSendAssessmentSidebar
                  job={job}
                  jobCandidateNanoId={personNanoId}
                />
              )}
            </>
          ) : null}
        </>
      )}
    </Fragment>
  );
}

export default SourcingTalentProfilePageContent;
