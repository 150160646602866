import {
  JobJobCandidateItemBffID,
  FetchJobJobCandidatesBffURL,
  FetchJobJobCandidateBffURL,
  CreateJobJobCandidateBffURL,
  DeleteJobJobCandidateBffURL,
  JobJobCandidateItemBffUuID
} from './jobJobCandidatesTypes';

import { BaseBffRoutes } from '../BaseBffRoutes';

export class JobJobCandidatesBffRoutes extends BaseBffRoutes {
  protected static route = 'jobJobCandidates';

  static fetchJobJobCandidatesRoute() {
    return this.fetchItemsRoute<FetchJobJobCandidatesBffURL>();
  }

  static fetchJobJobCandidatesIndexRoute() {
    return this.fetchItemsIndexRoute<FetchJobJobCandidatesBffURL>();
  }

  static fetchJobJobCandidateRoute(
    jobJobCandidateItemBffID: JobJobCandidateItemBffID
  ) {
    return this.fetchItemRoute<FetchJobJobCandidateBffURL>(
      jobJobCandidateItemBffID
    );
  }

  static fetchJobJobCandidateIndexRoute(
    jobJobCandidateItemBffUuID: JobJobCandidateItemBffUuID
  ) {
    return this.fetchItemIndexRoute<FetchJobJobCandidateBffURL>(
      jobJobCandidateItemBffUuID
    );
  }

  static createJobJobCandidateRoute() {
    return this.createItemRoute<CreateJobJobCandidateBffURL>();
  }

  static deleteJobJobCandidateRoute(
    jobJobCandidateItemBffID: JobJobCandidateItemBffID
  ) {
    return this.deleteItemRoute<DeleteJobJobCandidateBffURL>(
      jobJobCandidateItemBffID
    );
  }
}
