import { Tag, TypographyProps } from '@chakra-ui/react';

import { renderJobCandidateSearchTypeLabel } from '../../utils/renderJobCandidateSearchTypeLabel';

import {
  JobCandidateSearchType,
  JobCandidateSearchTypeLabel,
  JobCandidateSearchTypes
} from '../../jobCandidatesTypes';

import { labelColors } from './JobCandidateLabelHelper.types';
import { renderJobCandidateSearchType } from '../../utils/renderJobCandidateSearchType';
import { UserVoiceIcon } from '../../../../icons/UserVoiceIcon';

interface JobCandidateLabelHelperWithSearchType {
  searchTypeLabel?: never;
  searchType: JobCandidateSearchType;
}

interface JobCandidateLabelHelperWithSearchTypeLabel {
  searchTypeLabel: JobCandidateSearchTypeLabel;
  searchType?: never;
}

type JobCandidateLabelHelperProps = {
  useIcon?: boolean;
  fontSize?: TypographyProps['fontSize'];
} & (
  | JobCandidateLabelHelperWithSearchType
  | JobCandidateLabelHelperWithSearchTypeLabel
);

function JobCandidateLabelHelper({
  useIcon,
  searchTypeLabel,
  searchType,
  fontSize = 'xs'
}: JobCandidateLabelHelperProps) {
  const type = searchTypeLabel || searchType;
  const isApplicantOrRecruit =
    type === JobCandidateSearchTypes.APPLICANT ||
    type === JobCandidateSearchTypes.PROSPECT;

  return useIcon && isApplicantOrRecruit ? (
    <UserVoiceIcon
      fill={
        type === JobCandidateSearchTypes.APPLICANT ? 'green.500' : 'primary.500'
      }
      transform={
        type === JobCandidateSearchTypes.APPLICANT ? 'scaleX(1)' : 'scaleX(-1)'
      }
      w={5}
      h={5}
    />
  ) : (
    <Tag
      size="sm"
      fontSize={fontSize}
      color={labelColors[searchTypeLabel || searchType]?.color}
      bg={labelColors[searchTypeLabel || searchType]?.bg}
      borderRadius="base"
      border="1px solid"
      borderColor={labelColors[searchTypeLabel || searchType]?.border}
    >
      {searchType
        ? renderJobCandidateSearchType(searchType)
        : renderJobCandidateSearchTypeLabel(searchTypeLabel)}
    </Tag>
  );
}

export default JobCandidateLabelHelper;
