import React, { ReactNode } from 'react';
import { Text } from '../../../../../../../../../../helpers/Text';
import { HStack, Stack } from '@chakra-ui/react';
import { CheckIcon } from '../../../../../../../../../../icons/CheckIcon';

interface ViewJobCandidateDetailsProfileOverviewMyNxmoovItemProps {
  label: string;
  value: ReactNode;
  isMatch?: boolean;
}

const ViewJobCandidateDetailsProfileOverviewMyNxmoovItem = ({
  label,
  value,
  isMatch
}: ViewJobCandidateDetailsProfileOverviewMyNxmoovItemProps) => (
  <Stack>
    <HStack>
      <Text
        noOfLines={1}
        color="gray.800"
        wordBreak="break-all"
        textStyle="body1Medium"
      >
        {label}
      </Text>

      {isMatch && <CheckIcon w={4} h={4} fill="green.600" />}
    </HStack>

    <Text textStyle="body1Regular" lineHeight={1.5}>
      {value}
    </Text>
  </Stack>
);
export default ViewJobCandidateDetailsProfileOverviewMyNxmoovItem;
