import { Flex, Grid, Stack, StackDivider } from '@chakra-ui/react';
import size from 'lodash/size';
import find from 'lodash/find';
import filter from 'lodash/filter';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';

import {
  TalentPoolCandidateListItem,
  TalentPoolJobCandidate
} from '../TalentPoolCandidateListItem';

import { ErrorMessage, IsFetched } from '../../../../../types';
import { JobCandidateNanoId } from '../../../../jobCandidates/jobCandidatesTypes';
import { UserId } from '../../../../users/usersTypes';

import { DropdownSelect } from '../../../../../helpers/DropdownSelect';
import { DepartmentsCache } from '../../../../departments/DepartmentsCache';
import {
  DepartmentFields,
  DepartmentId,
  DepartmentNanoId,
  FetchDepartmentsFilters,
  FetchDepartmentsSort
} from '../../../../departments/departmentsTypes';
import { usePaginatedDepartments } from '../../../../departments/hooks/usePaginatedDepartments';
import {
  FetchDepartmentsResponse,
  fetchDepartmentsQuery
} from '../../../../departments/queries/fetchDepartments.query';
import { NoTalentPlaceholder } from '../../helpers/NoTalentPlaceholder';
import { useCurrentUser } from '../../../../../auth/hooks/useAuth';
import { useEffect, useRef, useState } from 'react';
import { CheckboxField } from '../../../../../helpers/forms/formFields/CheckboxField';

interface TalentPoolCandidatesListProps {
  jobCandidates: TalentPoolJobCandidate[];
  jobCandidatesTotalCount: number;
  jobCandidatesIsFetched: IsFetched;
  jobCandidatesErrorMessage: ErrorMessage;
  department?: {
    nanoId: DepartmentNanoId;
    id: DepartmentId;
  };
  personNanoId?: JobCandidateNanoId;
  currentUser: {
    id: UserId;
  };
  jobCandidateRoute: (
    departmentNanoId: DepartmentNanoId,
    personNanoId: JobCandidateNanoId
  ) => string;
  isDisabled?: boolean;
  fullHeight?: boolean;
  withoutProfileShare?: boolean;
  isHiringPortal?: boolean;
  filterCandidatesByDepartment: (departmentNanoId: DepartmentId) => void;
}

function TalentPoolCandidatesList({
  department,
  currentUser,
  personNanoId,
  jobCandidates,
  jobCandidatesIsFetched,
  jobCandidatesErrorMessage,
  jobCandidateRoute,
  filterCandidatesByDepartment,
  isDisabled,
  isHiringPortal,
  withoutProfileShare
}: TalentPoolCandidatesListProps) {
  const { companyId } = useCurrentUser();
  const [departmentId, setDepartmentId] = useState<DepartmentId>(
    department?.id as DepartmentId
  );

  const containerRef = useRef<HTMLDivElement>(null);

  const { departments, departmentsIsLoading } =
    usePaginatedDepartments<FetchDepartmentsResponse>({
      query: fetchDepartmentsQuery,
      cacheKey: DepartmentsCache.indexCacheKey(),
      initialSort: {
        [DepartmentFields.NAME]: { ascending: true }
      } as unknown as FetchDepartmentsSort,
      initialFilters: {
        ...(companyId
          ? {
              [DepartmentFields.COMPANY_ID]: {
                operator: 'eq',
                value: companyId
              }
            }
          : {})
      } as unknown as FetchDepartmentsFilters
    });

  const activeJobCandidate = find(
    jobCandidates,
    (jC) => jC.nanoId === personNanoId
  );

  const otherJobCandidates = filter(
    jobCandidates,
    (jC) => jC.nanoId !== personNanoId
  );

  useEffect(() => {
    if (personNanoId) {
      containerRef.current?.scrollIntoView();
    }
  }, [personNanoId]);

  const selectedDepartment = find(
    departments,
    (dept) => String(dept.id) === String(departmentId)
  );

  return (
    <Grid
      gap={3}
      w={{
        base: '290px',
        xl: '320px'
      }}
      bg="white"
      pos="fixed"
      overflowY="auto"
      borderRight="1px solid"
      borderColor="gray.200"
      h={'calc(100vh - 130px)'}
      gridTemplateRows="max-content 1fr"
    >
      <Flex
        pl={4}
        pr={2}
        pt={3}
        pb={3.5}
        top={0}
        gap={2}
        zIndex={10}
        bg="gray.50"
        pos="sticky"
        alignItems="center"
      >
        <Flex flex={1} gap={2} alignItems="center">
          <CheckboxField size="small" />
          <DropdownSelect
            h={9}
            px={3}
            pr={2}
            w="full"
            fontSize="sm"
            size="small"
            boxShadow="none"
            popoverWidth="xs"
            border="1px solid"
            borderColor="gray.200"
            isLoading={departmentsIsLoading}
            isDisabled={isDisabled}
            selectedOption={String(departmentId || department?.id || 'all')}
            placeholder="Select department"
            onOptionSelect={(val) => {
              setDepartmentId(val as DepartmentId);
              filterCandidatesByDepartment(val as DepartmentId);
            }}
            options={[
              { label: 'All Departments', value: 'all' },
              ...departments.map((department) => ({
                label: department.name,
                value: String(department.id)
              }))
            ]}
            hierarchy="tertiary"
            updateWithSelectedOption
          />
        </Flex>
      </Flex>

      <LoadingSkeleton loaded={jobCandidatesIsFetched}>
        <Stack
          spacing={0}
          overflow="auto"
          divider={<StackDivider borderColor="gray.100" />}
        >
          <AlertMessage message={jobCandidatesErrorMessage} />
          {size(jobCandidates) ? (
            <>
              {activeJobCandidate ? (
                <TalentPoolCandidateListItem
                  jobCandidate={activeJobCandidate}
                  department={selectedDepartment || department}
                  currentUser={currentUser}
                  isActive
                  jobCandidateRoute={jobCandidateRoute}
                  isDisabled={isDisabled}
                  withoutProfileShare={withoutProfileShare}
                  itemRef={containerRef}
                />
              ) : null}
              {otherJobCandidates.map((jobCandidate) => (
                <TalentPoolCandidateListItem
                  key={jobCandidate.nanoId}
                  jobCandidate={jobCandidate}
                  department={selectedDepartment || department}
                  currentUser={currentUser}
                  isActive={false}
                  jobCandidateRoute={jobCandidateRoute}
                  isDisabled={isDisabled}
                  withoutProfileShare={withoutProfileShare}
                  isHiringPortal={isHiringPortal}
                />
              ))}
            </>
          ) : (
            <NoTalentPlaceholder profileType="prospect" />
          )}
        </Stack>
      </LoadingSkeleton>
    </Grid>
  );
}

export default TalentPoolCandidatesList;
