import {
  Box,
  ButtonGroup,
  Flex,
  PlacementWithLogical,
  Popover,
  PopoverAnchor,
  PopoverBody,
  PopoverContent,
  Portal,
  Skeleton,
  Stack,
  StackDivider
} from '@chakra-ui/react';
import debounce from 'lodash/debounce';
import { Fragment, ReactNode, useState } from 'react';
import { Button } from '../../../../../../helpers/Button';
import { Search } from '../../../../../../helpers/Search';
import { Text } from '../../../../../../helpers/Text';
import { JobsCache } from '../../../../JobsCache';
import { usePaginatedJobs } from '../../../../hooks/usePaginatedJobs';
import {
  FetchJobsCountType,
  FetchJobsSort,
  JobFields
} from '../../../../jobsTypes';
import { fetchJobQuery } from '../../../../queries/fetchJob.query';
import {
  FetchJobsResponse,
  fetchJobsQuery
} from '../../../../queries/fetchJobs.query';
import { CheckIcon } from '../../../../../../icons/CheckIcon';
import { ViewJobCandidateAddToJobItem } from '../../../../components/modals/ViewJobCandidateModal/tabs/ViewJobCandidateModalProfileTab/components/ViewJobCandidateAddToJob/ViewJobCandidateAddToJob';
import compact from 'lodash/compact';

import { jobsInitialPageSize } from '../../../../jobs.data';
import { renderJobTitle } from '../../../../utils/renderJobTitle';
import { ErrorMessage, IsLoading } from '../../../../../../types';
import { AlertMessage } from '../../../../../../helpers/AlertMessage';
import { useCurrentUser } from '../../../../../../auth/hooks/useAuth';
import { publishedJobsInCompanyFilters } from '../../../JobsIndexPage/JobsIndexPage.query';
import { AddedJobItem } from './AddCandidateToJob.types';

interface AddCandidateToJobProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  onAddToJob: (job: ViewJobCandidateAddToJobItem) => void;
  placement?: PlacementWithLogical;
  offset?: [number, number];
  addedJobs?: AddedJobItem[];
  addToJobIsLoading?: IsLoading;
  addToJobErrorMessage?: ErrorMessage;
}

const AddCandidateToJob = ({
  isOpen,
  onClose,
  children,
  offset,
  placement,
  onAddToJob,
  addedJobs,
  addToJobErrorMessage,
  addToJobIsLoading
}: AddCandidateToJobProps) => {
  return (
    <Popover
      isLazy
      isOpen={isOpen}
      onClose={onClose}
      closeOnEsc={true}
      closeOnBlur={true}
      returnFocusOnClose={false}
      placement={placement || 'right-start'}
      offset={offset || [20, 22]}
    >
      <PopoverAnchor>
        <Stack>{children}</Stack>
      </PopoverAnchor>

      <Portal>
        <PopoverContent p={0}>
          <AddCandidateToJobBody
            onClose={onClose}
            onAddToJob={onAddToJob}
            addedJobs={addedJobs}
            addToJobErrorMessage={addToJobErrorMessage}
            addToJobIsLoading={addToJobIsLoading}
          />
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default AddCandidateToJob;

interface AddCandidateToJobBodyProps {
  onAddToJob: (job: ViewJobCandidateAddToJobItem) => void;
  onClose: () => void;
  addedJobs?: AddedJobItem[];
  addToJobIsLoading?: IsLoading;
  addToJobErrorMessage?: ErrorMessage;
}

const AddCandidateToJobBody = ({
  onAddToJob,
  onClose,
  addedJobs,
  addToJobErrorMessage,
  addToJobIsLoading
}: AddCandidateToJobBodyProps) => {
  const currentUser = useCurrentUser();
  const [selectedJob, setSelectedJob] = useState<
    ViewJobCandidateAddToJobItem | undefined
  >();

  const { jobs, jobsIsLoading, changeJobsFilters, jobsErrorMessage } =
    usePaginatedJobs<FetchJobsResponse>({
      cacheKey: JobsCache.indexCacheKey(),
      query: fetchJobsQuery,
      countType: 'exact' as FetchJobsCountType,
      fetchItemCacheKey: JobsCache.showCacheKey(),
      itemQuery: fetchJobQuery,
      initialSort: {
        [JobFields.NAME]: { ascending: true }
      } as unknown as FetchJobsSort,
      initialPageSize: jobsInitialPageSize,
      initialFilters: publishedJobsInCompanyFilters({
        companyId: currentUser.companyId
      })
    });

  const debouncedSearch = debounce(
    (e) =>
      changeJobsFilters({
        [JobFields.NAME]: {
          operator: 'ilike',
          value: `%${e.target.value}%`
        }
      }),
    500
  );

  const addToJob = () => {
    selectedJob && onAddToJob(selectedJob);
  };

  return (
    <PopoverBody p={0}>
      <Stack py={4} spacing={4} divider={<StackDivider />}>
        <Box px={3}>
          <Search placeholder="Search Jobs" onChange={debouncedSearch} />
        </Box>

        {jobsIsLoading && (
          <Stack px={3} spacing={3} overflow="auto" divider={<StackDivider />}>
            <Flex>
              <Skeleton w={40}>Loading</Skeleton>
            </Flex>

            <Flex>
              <Skeleton w={32}>Loading</Skeleton>
            </Flex>

            <Flex>
              <Skeleton w={48}>Loading</Skeleton>
            </Flex>
          </Stack>
        )}

        {jobs.length > 1 && (
          <Stack
            px={3}
            maxH={36}
            spacing={1}
            overflow="auto"
            divider={<StackDivider />}
          >
            {jobs.map((job: FetchJobsResponse) => (
              <Fragment key={job.nanoId}>
                <Button
                  p={2}
                  h="auto"
                  size="medium"
                  textAlign="left"
                  fontWeight="normal"
                  isDisabled={
                    !!addedJobs?.find((item) => item.nanoId === job.nanoId)
                  }
                  onClick={() => setSelectedJob(job)}
                  hierarchy="unstyled"
                  value={job.nanoId}
                  w="full"
                  transitionDuration="slow"
                  transitionProperty="background"
                  _hover={{
                    bg: 'gray.100'
                  }}
                >
                  <Flex w="100%" gap={2} flex={1} overflow="hidden">
                    <Flex flex={1} flexDir="column">
                      <Text
                        as="span"
                        flex={1}
                        textStyle="body1Medium"
                        whiteSpace="normal"
                      >
                        {renderJobTitle(job)}
                      </Text>

                      <Text
                        flex={1}
                        as="span"
                        color={
                          addedJobs?.find((item) => item.nanoId === job.nanoId)
                            ? 'gray.400'
                            : 'gray.600'
                        }
                        textStyle="body1Regular"
                        whiteSpace="normal"
                      >
                        {compact([job.department?.name, job.city?.label]).join(
                          ', '
                        )}
                      </Text>
                    </Flex>
                    <Flex w={4} h={6} alignItems="center">
                      <CheckIcon
                        transitionDuration="slow"
                        transitionProperty="opacity"
                        opacity={selectedJob?.nanoId === job.nanoId ? 1 : 0}
                      />
                    </Flex>
                  </Flex>
                </Button>
              </Fragment>
            ))}
          </Stack>
        )}

        {(addToJobErrorMessage || jobsErrorMessage) && (
          <AlertMessage message={addToJobErrorMessage || jobsErrorMessage} />
        )}

        <ButtonGroup px={3}>
          <Button
            w="full"
            size="small"
            onClick={addToJob}
            isDisabled={!selectedJob}
            isLoading={addToJobIsLoading}
          >
            Add
          </Button>

          <Button w="full" size="small" hierarchy="secondary" onClick={onClose}>
            Cancel
          </Button>
        </ButtonGroup>
      </Stack>
    </PopoverBody>
  );
};
