import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  StackDivider,
  Tooltip,
  useDisclosure
} from '@chakra-ui/react';
import { Button } from '../../../../../../helpers/Button';
import { IconButton } from '../../../../../../helpers/buttons/IconButton';
import { Heading } from '../../../../../../helpers/Heading';
import { ChevronDownIcon } from '../../../../../../icons/ChevronDownIcon';
import { EmailIcon } from '../../../../../../icons/EmailIcon';
import { LinkedInIcon } from '../../../../../../icons/LinkedInIcon';
import { MessageIcon } from '../../../../../../icons/MessageIcon';
import { PhoneIcon } from '../../../../../../icons/PhoneIcon';
import { ViewJobCandidateModalPhoneSidebarHelper } from '../../../../components/modals/ViewJobCandidateModal/helpers/ViewJobCandidateModalPhoneSidebarHelper';
import { useViewJobCandidateDetailsSendAssessmentContext } from '../../contexts/ViewJobCandidateDetailsSendAssessmentContext';
import { useViewJobCandidateDetailsSubmissionContext } from '../../contexts/ViewJobCandidateDetailsSubmissionContext';
import { JobCandidateDetailsJobCandidate } from '../../ViewJobCandidateDetailsPage.types';
import { ViewJobCandidateDetailsAddComment } from '../ViewJobCandidateDetailsAddComment';
import { ViewJobCandidateDetailsBio } from '../ViewJobCandidateDetailsBio';
import { ViewJobCandidateDetailsComposeEmail } from '../ViewJobCandidateDetailsComposeEmail';
import { ViewJobCandidateDetailsJobDetailsJob } from '../ViewJobCandidateDetailsJobDetails';
import { NextLinkHelper } from '../../../../../../helpers/NextLinkHelper';
import { IsFetched } from '../../../../../../types';
import { LoadingSkeleton } from '../../../../../../helpers/LoadingSkeleton';
import { renderJobTitle } from '../../../../utils/renderJobTitle';
import { JobLocationHelper } from '../../../../helpers/JobLocationHelper';
import { Text } from '../../../../../../helpers/Text';
import { JobPriorityDropdown } from '../../../../../updates/components/JobPriorityDropdown';
import { JobsIcon } from '../../../../../../icons/JobsIcon';
import { renderJobEmployment } from '../../../../utils/renderJobEmployment';
import { ClockIcon } from '../../../../../../icons/ClockIcon';
import { RelativeDateHelper } from '../../../../../../helpers/RelativeDateHelper';
import { HiringPortalRoutes } from '../../../../../hiringPortal/HiringPortalRoutes';
import { JobsRoutes } from '../../../../JobsRoutes';
import { PureButtonWithRefHelper } from '../../../../../../helpers/buttons/PureButtonWithRefHelper';
import { ViewJobCandidateDetailsJobHiringTeam } from '../ViewJobCandidateDetailsJobHiringTeam';
import { ViewJobCandidateDetailsHeaderMenuJobs } from '../ViewJobCandidateDetailsHeaderMenuJobs';

interface ViewJobCandidateDetailsHeaderMenuProps {
  jobCandidate: JobCandidateDetailsJobCandidate;
  hasScrolled: boolean;
  requiresCredit: boolean;
  job: ViewJobCandidateDetailsJobDetailsJob;
  jobIsFetched: IsFetched;
  isHiringPortalPath: boolean;
}

export default function ViewJobCandidateDetailsHeaderMenu({
  job,
  hasScrolled,
  jobCandidate,
  requiresCredit,
  jobIsFetched,
  isHiringPortalPath
}: ViewJobCandidateDetailsHeaderMenuProps) {
  const { isCandidateSubmissionOpen } =
    useViewJobCandidateDetailsSubmissionContext();

  const { isSendEmailAssessmentOpen } =
    useViewJobCandidateDetailsSendAssessmentContext();

  const isRightSidebarOpen =
    isCandidateSubmissionOpen || isSendEmailAssessmentOpen;

  const {
    isOpen: isOpenPhonrCallActivity,
    onOpen: onOpenPhonrCallActivity,
    onToggle: onTogglePhonrCallActivity
  } = useDisclosure();

  return (
    <>
      <Flex
        h={14}
        top={0}
        gap={6}
        zIndex={99}
        position="sticky"
        overflow="hidden"
        bg={isRightSidebarOpen ? 'gray.50' : 'white'}
        pl={{ base: 4, xl: 12 }}
        pr={isRightSidebarOpen ? 4 : isHiringPortalPath ? 12 : 4}
      >
        {isRightSidebarOpen ? (
          <Flex alignItems="center">
            <Heading level="h3">
              Candidate {isCandidateSubmissionOpen && 'Submission'}{' '}
              {isSendEmailAssessmentOpen && 'Assessment'}
            </Heading>
          </Flex>
        ) : (
          <>
            <Flex
              h={28}
              top={0}
              flex={1}
              w="100%"
              flexDir="column"
              position="relative"
              transitionDuration="slow"
              transform={hasScrolled ? 'translate(0, -50%)' : undefined}
            >
              <Flex
                gap={4}
                opacity={hasScrolled ? 0 : 1}
                w="100%"
                flex={1}
                alignItems="center"
                flexWrap="nowrap"
              >
                <LoadingSkeleton count={1} loaded={jobIsFetched}>
                  {job ? (
                    <HStack
                      spacing={4}
                      divider={<StackDivider />}
                      flexWrap="nowrap"
                    >
                      <Tooltip
                        label={renderJobTitle(job)}
                        aria-label="Name"
                        placement="top"
                      >
                        <span>
                          <ViewJobCandidateDetailsHeaderMenuJobs
                            jobCandidate={jobCandidate}
                            job={job}
                            jobDetailsRoute={
                              isHiringPortalPath
                                ? HiringPortalRoutes.candidatesJobDetails
                                : JobsRoutes.viewDetails
                            }
                          />
                        </span>
                      </Tooltip>

                      <Flex gap={2} alignItems="center" shrink={0}>
                        <Text>Job Priority</Text>
                        <JobPriorityDropdown priority="high" />
                      </Flex>

                      <JobLocationHelper job={job} withIcon />

                      <HStack spacing={2} sx={{ flexShrink: '0' }}>
                        <JobsIcon w="20px" h="20px" color="gray.500" />

                        <Text>
                          {renderJobEmployment(job.employment) || '-'}
                        </Text>
                      </HStack>

                      <HStack spacing={2} sx={{ flexShrink: '0' }}>
                        <ClockIcon w="20px" h="20px" color="gray.500" />

                        <Text>
                          <RelativeDateHelper date={job.createdAt as string} />
                        </Text>
                      </HStack>

                      <NextLinkHelper
                        href={
                          isHiringPortalPath
                            ? HiringPortalRoutes.candidatesJobDetails(
                                job.nanoId
                              )
                            : JobsRoutes.viewDetails(job.nanoId)
                        }
                        passHref
                      >
                        <PureButtonWithRefHelper
                          as="a"
                          size="medium"
                          hierarchy="link"
                          i18nText="Job Details"
                          _hover={{ textDecoration: 'none' }}
                          sx={{ flexShrink: '0' }}
                        />
                      </NextLinkHelper>

                      <ViewJobCandidateDetailsJobHiringTeam job={job} />
                    </HStack>
                  ) : null}
                </LoadingSkeleton>
              </Flex>

              <Flex
                h={14}
                gap={2}
                flex={1}
                w="100%"
                alignItems="center"
                top={hasScrolled ? 0 : '100%'}
                transitionDuration="slow"
                zIndex={999}
              >
                <HStack spacing={4} divider={<StackDivider />}>
                  <HStack spacing={2}>
                    {jobCandidate && job ? (
                      <ViewJobCandidateDetailsBio
                        requiresCredit={requiresCredit}
                        jobCandidate={jobCandidate}
                        isCollapsed
                        job={job}
                        isHiringPortalPath={isHiringPortalPath}
                      />
                    ) : null}

                    <Flex
                      w={6}
                      h={6}
                      bg="#069"
                      alignItems="center"
                      borderRadius="full"
                      justifyContent="center"
                    >
                      <LinkedInIcon borderRadius="full" w={3.5} h={3.5} />
                    </Flex>
                  </HStack>

                  {job ? (
                    <HStack spacing={4}>
                      <Tooltip
                        label={renderJobTitle(job)}
                        aria-label="Name"
                        placement="top"
                      >
                        <span>
                          <ViewJobCandidateDetailsHeaderMenuJobs
                            jobCandidate={jobCandidate}
                            job={job}
                            jobDetailsRoute={
                              isHiringPortalPath
                                ? HiringPortalRoutes.candidatesJobDetails
                                : JobsRoutes.viewDetails
                            }
                          />
                        </span>
                      </Tooltip>

                      <JobLocationHelper job={job} withIcon />
                    </HStack>
                  ) : null}
                </HStack>

                <Flex gap={3} alignItems="center" ml="auto">
                  <ViewJobCandidateDetailsComposeEmail
                    trigger={
                      <Tooltip
                        label="Email"
                        textTransform="capitalize"
                        hasArrow
                      >
                        <IconButton
                          h={6}
                          w={6}
                          minW={0}
                          minH={0}
                          value="email"
                          display="flex"
                          aria-label=""
                          alignItems="center"
                          borderRadius="full"
                          justifyContent="center"
                          icon={<EmailIcon w={3.5} h={3.5} />}
                          hierarchy="tertiary"
                        />
                      </Tooltip>
                    }
                  />

                  <ViewJobCandidateDetailsAddComment
                    trigger={
                      <Tooltip
                        label="Comment"
                        textTransform="capitalize"
                        hasArrow
                      >
                        <IconButton
                          h={6}
                          w={6}
                          minW={0}
                          minH={0}
                          value="comment"
                          display="flex"
                          aria-label=""
                          alignItems="center"
                          borderRadius="full"
                          justifyContent="center"
                          icon={<MessageIcon w={3.5} h={3.5} />}
                          hierarchy="tertiary"
                        />
                      </Tooltip>
                    }
                  />

                  <HStack
                    spacing={0}
                    overflow="hidden"
                    borderRadius="base"
                    divider={<StackDivider borderColor="primary.100" />}
                  >
                    <Button
                      h={7}
                      px={4}
                      size="extra-small"
                      borderRadius="none"
                      onClick={onOpenPhonrCallActivity}
                      leftIcon={<PhoneIcon w={3} h={3} fill="white" />}
                    >
                      Call
                    </Button>

                    <IconButton
                      h={7}
                      display="flex"
                      aria-label=""
                      size="extra-small"
                      alignItems="center"
                      borderRadius="none"
                      justifyContent="center"
                      icon={<ChevronDownIcon w={3.5} h={3.5} fill="white" />}
                    />
                  </HStack>
                </Flex>
              </Flex>
            </Flex>
          </>
        )}
      </Flex>

      <Drawer
        size="md"
        isOpen={isOpenPhonrCallActivity}
        onClose={onTogglePhonrCallActivity}
      >
        <DrawerOverlay />
        <DrawerContent p={0}>
          <DrawerBody p={0}>
            {jobCandidate ? (
              <ViewJobCandidateModalPhoneSidebarHelper
                fullWidth
                withoutBorder
                jobCandidate={jobCandidate}
                toggleFollowUpEmail={console.log}
                isSidebarOpen={isOpenPhonrCallActivity}
                sidebarToggle={onTogglePhonrCallActivity}
                personalPhoneNumber={jobCandidate.phone}
                companyPhoneNumber={jobCandidate.companyPhone}
              />
            ) : null}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
