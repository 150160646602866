import snakeCase from 'lodash/snakeCase';

import {
  FetchLikedJobCandidatesQuery,
  LikedJobCandidateCreatedAt,
  LikedJobCandidateFields,
  LikedJobCandidateId,
  LikedJobCandidateJobCandidateId,
  LikedJobCandidateUser,
  LikedJobCandidateUserId
} from '../likedJobCandidatesTypes';

export interface FetchLikedJobCandidatesResponse {
  [LikedJobCandidateFields.ID]: LikedJobCandidateId;
  [LikedJobCandidateFields.USER]: LikedJobCandidateUser;
  [LikedJobCandidateFields.USER_ID]: LikedJobCandidateUserId;
  [LikedJobCandidateFields.JOB_CANDIDATE_ID]: LikedJobCandidateJobCandidateId;
  [LikedJobCandidateFields.CREATED_AT]: LikedJobCandidateCreatedAt;
}

export const fetchLikedJobCandidatesColumns = [
  LikedJobCandidateFields.ID,
  LikedJobCandidateFields.USER_ID,
  LikedJobCandidateFields.JOB_CANDIDATE_ID,
  LikedJobCandidateFields.CREATED_AT
];

const fetchLikedJobCandidatesSBColumns = fetchLikedJobCandidatesColumns.map(
  (fetchLikedJobCandidatesColumn) => snakeCase(fetchLikedJobCandidatesColumn)
);

export const fetchLikedJobCandidatesQuery = `
  ${fetchLikedJobCandidatesSBColumns.join(',')}
` as FetchLikedJobCandidatesQuery;
