import { Avatar, HStack } from '@chakra-ui/react';
import map from 'lodash/map';
import { Text } from '../../../../../../helpers/Text';
import { JobInterviewers, MayBeJobHiringManager } from '../../../../jobsTypes';
import { ImagesUrl } from '../../../../../images/ImagesUrl';

interface ViewJobCandidateDetailsJobHiringTeamProps {
  job: {
    hiringManager: MayBeJobHiringManager;
    interviewers: JobInterviewers;
  };
}

export default function ViewJobCandidateDetailsJobHiringTeam({
  job
}: ViewJobCandidateDetailsJobHiringTeamProps) {
  const { hiringManager, interviewers } = job || {};
  const hiringTeam = map(
    [...(hiringManager ? [hiringManager] : []), ...(interviewers || [])],
    (ht) => (ht.image ? ImagesUrl.file(ht.image) : '')
  );
  return (
    <HStack sx={{ flexShrink: '0' }}>
      <Text>Hiring team</Text>

      <HStack spacing={-3} overflow="hidden" transitionDuration="slow">
        {hiringTeam?.map((item, i) => (
          <Avatar
            key={`${item}-${i}`}
            h={7}
            w={7}
            border="1px solid"
            borderColor="white"
            src={item || ''}
          />
        ))}
      </HStack>
    </HStack>
  );
}
