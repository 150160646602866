import {
  FieldBrand,
  MayBe,
  ItemID,
  ItemUUID,
  ItemNanoId,
  ItemEmail,
  ItemCreatedAt,
  DateTime,
  ItemUpdatedAt
} from '../../../types';

import {
  CityId,
  CityNanoId,
  CityName,
  CityLabel,
  CityIds,
  CityState
} from '../../cities/citiesTypes';

import {
  CompanyId,
  CompanyNanoId,
  CompanyName
} from '../../companies/companiesTypes';

import {
  JobId,
  JobNanoId,
  JobName,
  JobJobTitle,
  JobDepartment
} from '../../jobs/jobsTypes';

import {
  JobResumeId,
  JobResumeNanoId,
  JobResumeFirstName,
  JobResumeLastName,
  JobResumeCreatedAt
} from '../../jobResumes/jobResumesTypes';

import {
  StateId,
  StateNanoId,
  StateName,
  StateIsoCode
} from '../../states/statesTypes';

import {
  SkillBadgeCertified,
  SkillBadgeExperience,
  SkillBadgeId,
  SkillBadgeNanoId,
  SkillBadgeRating,
  SkillBadgeRequirements,
  SkillBadgeSkill,
  SkillBadgeSkillLicense
} from '../../skillBadges/skillBadgesTypes';

import {
  JobCandidateCertificationId,
  JobCandidateCertificationNanoId,
  JobCandidateCertificationName,
  MayBeJobCandidateCertificationStartDate,
  MayBeJobCandidateCertificationEndDate,
  MayBeJobCandidateCertificationIssueDate,
  MayBeJobCandidateCertificationCompany
} from '../../jobCandidateCertifications/jobCandidateCertificationsTypes';

import {
  JobCandidateEducationId,
  JobCandidateEducationNanoId,
  JobCandidateEducationDegree,
  JobCandidateEducationSchoolName,
  JobCandidateEducationSchoolType,
  MayBeJobCandidateEducationStartDate,
  MayBeJobCandidateEducationEndDate,
  MayBeJobCandidateEducationState,
  MayBeJobCandidateEducationCity,
  JobCandidateEducationStudyField
} from '../../jobCandidateEducations/jobCandidateEducationsTypes';

import {
  JobCandidateEmploymentId,
  JobCandidateEmploymentNanoId,
  JobCandidateEmploymentName,
  JobCandidateEmploymentStartDate,
  JobCandidateEmploymentEndDate,
  JobCandidateEmploymentCompany,
  JobCandidateEmploymentCity,
  JobCandidateEmploymentState
} from '../../jobCandidateEmployments/jobCandidateEmploymentsTypes';

import {
  ImageId,
  ImageNanoId,
  ImageName,
  ImageFile,
  ImageKey,
  ImageCreatedAt
} from '../../images/imagesTypes';

import {
  UserId,
  UserNanoId,
  UserName,
  UserImage
} from '../../users/usersTypes';

import {
  WorkflowStageArrangement,
  WorkflowStageColor,
  WorkflowStageId,
  WorkflowStageName,
  WorkflowStageNanoId,
  WorkflowStageWorkflowStageTasks
} from '../../workflowStages/workflowStagesTypes';

import {
  WorkflowStageTaskArrangement,
  WorkflowStageTaskDueDate,
  WorkflowStageTaskId,
  WorkflowStageTaskName,
  WorkflowStageTaskNanoId
} from '../../workflowStageTasks/workflowStageTasksTypes';
import {
  IndustryId,
  IndustryIds,
  IndustryName
} from '../../industries/industriesTypes';
import {
  SpecializationId,
  SpecializationIds,
  SpecializationName
} from '../../specializations/specializationsTypes';
import { SkillIds } from '../../skills/skillsTypes';
import { TalentPoolId } from '../../talentPools/talentPoolsTypes';
import {
  LikedJobCandidateId,
  LikedJobCandidateJobCandidateId,
  LikedJobCandidateUserId
} from '../../likedJobCandidates/likedJobCandidatesTypes';
import {
  CandidateShareCandidateShareInvitations,
  CandidateShareCompanyMemberInvitations,
  CandidateShareId,
  CandidateShareRecipientIds,
  CandidateShareRecipients,
  CandidateShareUpdatedAt,
  CandidateShareUser,
  CandidateShareUserId
} from '../../candidateShares/candidateSharesTypes';
import {
  CandidateSubmissionCandidateSubmissionInvitations,
  CandidateSubmissionCompanyMemberInvitations,
  CandidateSubmissionId,
  CandidateSubmissionRecipientIds,
  CandidateSubmissionRecipients,
  CandidateSubmissionUpdatedAt,
  CandidateSubmissionUser,
  CandidateSubmissionUserId
} from '../../candidateSubmissions/candidateSubmissionsTypes';

export type JobCandidateId = FieldBrand<ItemID, 'JobCandidateId'>;
export type JobCandidateUuid = FieldBrand<ItemUUID, 'JobCandidateUuid'>;
export type MayBeJobCandidateUuid = MayBe<JobCandidateUuid>;
export type JobCandidateNanoId = FieldBrand<ItemNanoId, 'JobCandidateNanoId'>;
export type MayBeJobCandidateNanoId = MayBe<JobCandidateNanoId>;
export type JobCandidateName = FieldBrand<string, 'JobCandidateName'>;
export type JobCandidateFirstName = FieldBrand<string, 'JobCandidateFirstName'>;
export type JobCandidateLastName = FieldBrand<string, 'JobCandidateLastName'>;
export type JobCandidateEmail = FieldBrand<ItemEmail, 'JobCandidateEmail'>;
export type JobCandidatePhone = FieldBrand<string, 'JobCandidatePhone'>;
export type JobCandidateLinkedinUrl = FieldBrand<
  string,
  'JobCandidateLinkedinUrl'
>;
export type JobCandidateArrangement = FieldBrand<
  number,
  'JobCandidateArrangement'
>;
export type JobCandidateJobTitle = FieldBrand<string, 'JobCandidateJobTitle'>;
export type JobCandidatePersonalPhone = FieldBrand<
  string,
  'JobCandidatePersonalPhone'
>;
export type JobCandidatePersonalEmail = FieldBrand<
  string,
  'JobCandidatePersonalEmail'
>;
export type JobCandidateCompanyPhone = FieldBrand<
  string,
  'JobCandidateCompanyPhone'
>;
export type JobCandidateCompanyEmail = FieldBrand<
  string,
  'JobCandidateCompanyEmail'
>;
export type JobCandidateAssignToJob = FieldBrand<
  string,
  'JobCandidateAssignToJob'
>;
export type JobCandidateSource = FieldBrand<string, 'JobCandidateSource'>;
export type JobCandidateTags = FieldBrand<string[], 'JobCandidateTags'>;
export type JobCandidateSkills = FieldBrand<string[], 'JobCandidateSkills'>;
export type JobCandidateOtherDetails = FieldBrand<
  string,
  'JobCandidateOtherDetails'
>;
export type JobCandidateDescription = FieldBrand<
  string,
  'JobCandidateDescription'
>;
export type JobCandidateImageId = FieldBrand<ImageId, 'JobCandidateImageId'>;
export type MayBeJobCandidateImageId = MayBe<JobCandidateImageId>;
export type JobCandidateImage = FieldBrand<
  {
    id: ImageId;
    nanoId: ImageNanoId;
    name: ImageName;
    file: ImageFile;
    key: ImageKey;
    createdAt: ImageCreatedAt;
  },
  'JobCandidateImage'
>;
export type MayBeJobCandidateImage = MayBe<JobCandidateImage>;
export type JobCandidateIndustryIds = FieldBrand<
  IndustryIds,
  'JobCandidateIndustryIds'
>;
export type JobCandidateIndustries = FieldBrand<
  { id: IndustryId; name: IndustryName }[],
  'JobCandidateIndustries'
>;
export type JobCandidateSpecializationIds = FieldBrand<
  SpecializationIds,
  'JobCandidateSpecializationIds'
>;
export type JobCandidateSpecializations = FieldBrand<
  { id: SpecializationId; name: SpecializationName }[],
  'JobCandidateSpecializations'
>;
export type JobCandidateWorkplaceTypes = FieldBrand<
  string[],
  'JobCandidateWorkplaceTypes'
>;
export type JobCandidateEmploymentType = FieldBrand<
  string,
  'JobCandidateEmploymentType'
>;
export type JobCandidateEmploymentTypes = FieldBrand<
  JobCandidateEmploymentType[],
  'JobCandidateEmploymentTypes'
>;
export type JobCandidatePaidTimeOff = FieldBrand<
  string,
  'JobCandidatePaidTimeOff'
>;
export type JobCandidateMinimumDesiredPay = FieldBrand<
  string,
  'JobCandidateMinimumDesiredPay'
>;
export type MayBeJobCandidateMinimumDesiredPay =
  MayBe<JobCandidateMinimumDesiredPay>;
export type JobCandidateMaximumDesiredPay = FieldBrand<
  string,
  'JobCandidateMaximumDesiredPay'
>;
export type MayBeJobCandidateMaximumDesiredPay =
  MayBe<JobCandidateMaximumDesiredPay>;
export type JobCandidatePrimaryJobFunction = FieldBrand<
  string,
  'JobCandidatePrimaryJobFunction'
>;
export type JobCandidateNewJobStartAvailability = FieldBrand<
  string,
  'JobCandidateNewJobStartAvailability'
>;
export type JobCandidateActivelyInterviewing = FieldBrand<
  string,
  'JobCandidateActivelyInterviewing'
>;
export type JobCandidateCounterOffer = FieldBrand<
  string,
  'JobCandidateCounterOffer'
>;
export type JobCandidateEmployerCounterOffer = FieldBrand<
  string,
  'JobCandidateEmployerCounterOffer'
>;
export type JobCandidateWorkAuthorization = FieldBrand<
  string,
  'JobCandidateWorkAuthorization'
>;
export type JobCandidateFutureRequirements = FieldBrand<
  string,
  'JobCandidateFutureRequirements'
>;
export type JobCandidateProfessionalExperience = FieldBrand<
  string,
  'JobCandidateProfessionalExperience'
>;
export type MayBeJobCandidateProfessionalExperience =
  MayBe<JobCandidateProfessionalExperience>;

export type JobCandidateCompanyId = FieldBrand<
  CompanyId,
  'JobCandidateCompanyId'
>;
export type MayBeJobCandidateCompanyId = MayBe<JobCandidateCompanyId>;
export type JobCandidateCompany = FieldBrand<
  {
    id: CompanyId;
    nanoId: CompanyNanoId;
    name: CompanyName;
  },
  'JobCandidateCompany'
>;
export type MayBeJobCandidateCompany = MayBe<JobCandidateCompany>;
export type JobCandidateCompanyName = FieldBrand<
  CompanyName,
  'JobCandidateCompanyName'
>;

export type JobCandidateCityId = FieldBrand<CityId, 'JobCandidateCityId'>;
export type MayBeJobCandidateCityId = MayBe<JobCandidateCityId>;
export type JobCandidateCity = FieldBrand<
  {
    id: CityId;
    nanoId: CityNanoId;
    name: CityName;
    label: CityLabel;
  },
  'JobCandidateCity'
>;
export type MayBeJobCandidateCity = MayBe<JobCandidateCity>;
export type JobCandidateCityIds = FieldBrand<CityIds, 'JobCandidateCityIds'>;
export type MayBeJobCandidateCityIds = MayBe<JobCandidateCityIds>;
export type JobCandidateCities = FieldBrand<
  {
    id: CityId;
    name: CityName;
    label: CityLabel;
    state: CityState;
  }[],
  'JobCandidateCities'
>;
export type MayBeJobCandidateCities = MayBe<JobCandidateCities>;
export type JobCandidateState = FieldBrand<
  {
    id: StateId;
    nanoId: StateNanoId;
    name: StateName;
    isoCode: StateIsoCode;
  },
  'JobCandidateState'
>;
export type MayBeJobCandidateState = MayBe<JobCandidateState>;
export type JobCandidateUserId = FieldBrand<UserId, 'JobCandidateUserId'>;
export type JobCandidateUser = FieldBrand<
  {
    id: UserId;
    nanoId: UserNanoId;
    name: UserName;
    image: UserImage;
  },
  'JobCandidateUser'
>;
export type JobCandidateJobId = FieldBrand<JobId, 'JobCandidateJobId'>;
export type JobCandidateJob = FieldBrand<
  {
    id: JobId;
    nanoId: JobNanoId;
    name: JobName;
    jobTitle: JobJobTitle;
    workflowStages: JobCandidateWorkflowStage[];
    department: JobDepartment;
  },
  'JobCandidateJob'
>;
export type JobCandidateTalentPools = FieldBrand<
  {
    id: TalentPoolId;
  }[],
  'JobCandidateTalentPools'
>;
export type JobCandidateJobResumeId = FieldBrand<
  JobResumeId,
  'JobCandidateJobResumeId'
>;
export type JobCandidateJobResume = FieldBrand<
  {
    id: JobResumeId;
    nanoId: JobResumeNanoId;
    firstName: JobResumeFirstName;
    lastName: JobResumeLastName;
    createdAt: JobResumeCreatedAt;
  },
  'JobCandidateResumeJob'
>;
export type JobCandidateWorkflowStageId = FieldBrand<
  WorkflowStageId,
  'JobCandidateWorkflowStageId'
>;
export type JobCandidateWorkflowStage = FieldBrand<
  {
    id: WorkflowStageId;
    nanoId: WorkflowStageNanoId;
    name: WorkflowStageName;
    color: WorkflowStageColor;
    arrangement: WorkflowStageArrangement;
    workflowStageTasks: WorkflowStageWorkflowStageTasks;
  },
  'JobCandidateWorkflowStage'
>;
export type JobCandidateWorkflowStageTaskId = FieldBrand<
  WorkflowStageTaskId,
  'JobCandidateWorkflowStageTaskId'
>;
export type JobCandidateWorkflowStageTask = FieldBrand<
  {
    id: WorkflowStageTaskId;
    nanoId: WorkflowStageTaskNanoId;
    name: WorkflowStageTaskName;
    dueDate: WorkflowStageTaskDueDate;
    arrangement: WorkflowStageTaskArrangement;
  },
  'JobCandidateWorkflowStageTask'
>;
export type MayBeJobCandidateWorkflowStageTask =
  MayBe<JobCandidateWorkflowStageTask>;
export type JobCandidateSkillIds = SkillIds;
export type JobCandidateSkillBadges = FieldBrand<
  {
    id: SkillBadgeId;
    nanoId: SkillBadgeNanoId;
    skill: SkillBadgeSkill;
    experience: SkillBadgeExperience;
    rating: SkillBadgeRating;
    certified: SkillBadgeCertified;
    skillLicense: SkillBadgeSkillLicense;
    requirements: SkillBadgeRequirements;
  }[],
  'JobCandidateSkillBadges'
>;
export type JobCandidateJobCandidateCertifications = FieldBrand<
  {
    id: JobCandidateCertificationId;
    nanoId: JobCandidateCertificationNanoId;
    name: JobCandidateCertificationName;
    startDate: MayBeJobCandidateCertificationStartDate;
    endDate: MayBeJobCandidateCertificationEndDate;
    issueDate: MayBeJobCandidateCertificationIssueDate;
    company: MayBeJobCandidateCertificationCompany;
  }[],
  'JobCandidateJobCandidateCertifications'
>;
export type JobCandidateJobCandidateEducations = FieldBrand<
  {
    id: JobCandidateEducationId;
    nanoId: JobCandidateEducationNanoId;
    degree: JobCandidateEducationDegree;
    studyField: JobCandidateEducationStudyField;
    schoolName: JobCandidateEducationSchoolName;
    schoolType: JobCandidateEducationSchoolType;
    startDate: MayBeJobCandidateEducationStartDate;
    endDate: MayBeJobCandidateEducationEndDate;
    state: MayBeJobCandidateEducationState;
    city: MayBeJobCandidateEducationCity;
  }[],
  'JobCandidateJobCandidateEducations'
>;
export type JobCandidateJobCandidateEmployments = FieldBrand<
  {
    id: JobCandidateEmploymentId;
    nanoId: JobCandidateEmploymentNanoId;
    name: JobCandidateEmploymentName;
    startDate: JobCandidateEmploymentStartDate;
    endDate: JobCandidateEmploymentEndDate;
    company: JobCandidateEmploymentCompany;
    state: JobCandidateEmploymentState;
    city: JobCandidateEmploymentCity;
  }[],
  'JobCandidateJobCandidateEmployments'
>;
export type JobCandidateCreatedAt = FieldBrand<
  ItemCreatedAt,
  'JobCandidateCreatedAt'
>;
export type JobCandidateUpdatedAt = FieldBrand<
  ItemUpdatedAt,
  'JobCandidateUpdatedAt'
>;
export type JobCandidateDisqualifiedAt = FieldBrand<
  DateTime,
  'JobCandidateDisqualifiedAt'
>;
export type MayBeJobCandidateDisqualifiedAt = MayBe<JobCandidateDisqualifiedAt>;
export type JobCandidateSearchType = FieldBrand<
  JobCandidateSearchTypes,
  'JobCandidateSearchType'
>;
export type JobCandidateSearchTypeLabel = FieldBrand<
  JobCandidateSearchTypeLabels,
  'JobCandidateSearchTypeLabel'
>;
export type JobCandidateStatus = FieldBrand<
  JobCandidateStatuses,
  'JobCandidateStatus'
>;
export type JobCandidateEmploymentStatus = FieldBrand<
  JobCandidateEmploymentStatuses,
  'JobCandidateEmploymentStatus'
>;
export type JobCandidatePhoneCallAvailability = FieldBrand<
  string,
  'JobCandidatePhoneCallAvailability'
>;
export type JobCandidateDisqualificationReason = FieldBrand<
  JobCandidateDisqualificationReasons,
  'JobCandidateDisqualificationReason'
>;
export type JobCandidateWorkflowStageTaskScheduledAt = FieldBrand<
  DateTime,
  'JobCandidateWorkflowStageTaskScheduledAt'
>;
export type MayBeJobCandidateWorkflowStageTaskScheduledAt =
  MayBe<JobCandidateWorkflowStageTaskScheduledAt>;
export type JobCandidateNewWorkflowStageTaskAt = FieldBrand<
  DateTime,
  'JobCandidateNewWorkflowStageTaskAt'
>;
export type MayBeJobCandidateNewWorkflowStageTaskAt =
  MayBe<JobCandidateNewWorkflowStageTaskAt>;
export type JobCandidateViewedAt = FieldBrand<DateTime, 'JobCandidateViewedAt'>;
export type MayBeJobCandidateViewedAt = MayBe<JobCandidateViewedAt>;
export type JobCandidateDueDate = FieldBrand<DateTime, 'JobCandidateDueDate'>;
export type MayBeJobCandidateDueDate = MayBe<JobCandidateDueDate>;
export type JobCandidateWorkflowStageTaskApplicationType = FieldBrand<
  JobCandidateWorkflowStageTaskApplicationTypes,
  'JobCandidateWorkflowStageTaskApplicationType'
>;
export type JobCandidateWorkflowStageTaskReactionType = FieldBrand<
  JobCandidateWorkflowStageTaskReactionTypes,
  'JobCandidateWorkflowStageTaskReactionType'
>;

export type JobCandidateLikedJobCandidateIds = FieldBrand<
  LikedJobCandidateId[],
  'JobCandidateLikedJobCandidateIds'
>;

export type JobCandidateLikedJobCandidates = FieldBrand<
  {
    id: LikedJobCandidateId;
    userId: LikedJobCandidateUserId;
    jobCandidateId: LikedJobCandidateJobCandidateId;
  }[],
  'JobCandidateLikedJobCandidates'
>;

export type JobCandidateCandidateShareIds = FieldBrand<
  CandidateShareId[],
  'JobCandidateCandidateShareIds'
>;
export type JobCandidateCandidateShare = FieldBrand<
  {
    id: CandidateShareId;
    recipientIds: CandidateShareRecipientIds;
    recipients: CandidateShareRecipients;
    companyMemberInvitations: CandidateShareCompanyMemberInvitations;
    candidateShareInvitations: CandidateShareCandidateShareInvitations;
    userId: CandidateShareUserId;
    user: CandidateShareUser;
    updatedAt: CandidateShareUpdatedAt;
  },
  'JobCandidateCandidateShare'
>;
export type JobCandidateCandidateShares = FieldBrand<
  JobCandidateCandidateShare[],
  'JobCandidateCandidateShares'
>;

export type JobCandidateCandidateSubmission = FieldBrand<
  {
    id: CandidateSubmissionId;
    recipientIds: CandidateSubmissionRecipientIds;
    recipients: CandidateSubmissionRecipients;
    companyMemberInvitations: CandidateSubmissionCompanyMemberInvitations;
    candidateSubmissionInvitations: CandidateSubmissionCandidateSubmissionInvitations;
    userId: CandidateSubmissionUserId;
    user: CandidateSubmissionUser;
    updatedAt: CandidateSubmissionUpdatedAt;
  },
  'JobCandidateCandidateSubmission'
>;
export type JobCandidateCandidateSubmissions = FieldBrand<
  JobCandidateCandidateSubmission[],
  'JobCandidateCandidateSubmissions'
>;

export const enum JobCandidateDisqualificationReasons {
  NONE = 'none',
  NOT_FIT = 'not_fit',
  NOT_INTERESTED = 'not_interested',
  NOT_AVAILABLE = 'not_available'
}

export const enum JobCandidateWorkflowStageTaskApplicationTypes {
  NONE = 'none',
  PHONE = 'phone',
  EMAIL = 'email',
  SCHEDULE = 'scgedule',
  ASSIGN = 'assign'
}

export const enum JobCandidateWorkflowStageTaskReactionTypes {
  NONE = 'none',
  ONE_MORE_DAY = 'one_more_day',
  SEND_REMINDER = 'send_reminder',
  SKIP_TASK = 'skip_task',
  TASK_COMPLETED = 'task_completed'
}

export const enum JobCandidateSearchTypes {
  NONE = 'none',
  LEAD = 'lead',
  PROSPECT = 'prospect',
  USER = 'user',
  CUSTOMER = 'customer',
  APPLICANT = 'applicant'
}

export const enum JobCandidateSearchTypeLabels {
  NONE = 'none',
  LEAD = 'lead',
  PROSPECT = 'prospect',
  NEW = 'new',
  CANDIDATE = 'candidate',
  APPLICANT = 'applicant'
}

// export const enum JobCandidateStatuses {
//   NONE = 0,
//   ACTIVE_AVAILABLE = 1,
//   ACTIVE_EMPLOYED = 2,
//   PASSIVELY_INTERESTED = 3,
//   PASSIVE_NOT_LOOKING = 4
// }

export const enum JobCandidateStatuses {
  NONE = 'none',
  ACTIVE_AVAILABLE = 'active_available',
  ACTIVE_EMPLOYED = 'active_employed',
  PASSIVELY_INTERESTED = 'passively_interested',
  PASSIVE_NOT_LOOKING = 'passive_not_looking'
}

export const enum JobCandidateEmploymentStatuses {
  NOT_EMPLOYED = 'not_employed',
  EMPLOYED = 'employed',
  STUDENT = 'student'
}

export const enum JobCandidatePipelineStatuses {
  NONE = 'none',
  RECRUITED = 'recruited',
  RECRUITING = 'recruiting',
  CONTACTED = 'contacted',
  SAVED = 'saved',
  REVIEWING = 'reviewing',
  REVIEWED = 'reviewed'
}
export type JobCandidatePipelineStatus = FieldBrand<
  JobCandidatePipelineStatuses,
  'JobCandidatePipelineStatus'
>;

export type JobCandidateMessage = FieldBrand<string, 'JobCandidateMessage'>;

export const jobCandidateWorkflowStageTaskInProgressReactions: JobCandidateWorkflowStageTaskReactionType[] =
  [
    JobCandidateWorkflowStageTaskReactionTypes.SKIP_TASK as JobCandidateWorkflowStageTaskReactionType,
    JobCandidateWorkflowStageTaskReactionTypes.TASK_COMPLETED as JobCandidateWorkflowStageTaskReactionType
  ];

export const jobCandidateWorkflowStageTaskOverdueReactions: JobCandidateWorkflowStageTaskReactionType[] =
  [
    JobCandidateWorkflowStageTaskReactionTypes.ONE_MORE_DAY as JobCandidateWorkflowStageTaskReactionType,
    JobCandidateWorkflowStageTaskReactionTypes.SEND_REMINDER as JobCandidateWorkflowStageTaskReactionType,
    JobCandidateWorkflowStageTaskReactionTypes.SKIP_TASK as JobCandidateWorkflowStageTaskReactionType,
    JobCandidateWorkflowStageTaskReactionTypes.TASK_COMPLETED as JobCandidateWorkflowStageTaskReactionType
  ];

export const jobCandidateDisqualificationReasons: JobCandidateDisqualificationReason[] =
  [
    JobCandidateDisqualificationReasons.NOT_FIT as JobCandidateDisqualificationReason,
    JobCandidateDisqualificationReasons.NOT_INTERESTED as JobCandidateDisqualificationReason,
    JobCandidateDisqualificationReasons.NOT_AVAILABLE as JobCandidateDisqualificationReason
  ];

export const jobCandidateWorkflowStageTaskApplicationTypes: JobCandidateWorkflowStageTaskApplicationType[] =
  [
    JobCandidateWorkflowStageTaskApplicationTypes.PHONE as JobCandidateWorkflowStageTaskApplicationType,
    JobCandidateWorkflowStageTaskApplicationTypes.EMAIL as JobCandidateWorkflowStageTaskApplicationType,
    JobCandidateWorkflowStageTaskApplicationTypes.SCHEDULE as JobCandidateWorkflowStageTaskApplicationType,
    JobCandidateWorkflowStageTaskApplicationTypes.ASSIGN as JobCandidateWorkflowStageTaskApplicationType
  ];

export const jobCandidateStatuses: JobCandidateStatus[] = [
  JobCandidateStatuses.NONE as JobCandidateStatus,
  JobCandidateStatuses.ACTIVE_AVAILABLE as JobCandidateStatus,
  JobCandidateStatuses.ACTIVE_EMPLOYED as JobCandidateStatus,
  JobCandidateStatuses.PASSIVELY_INTERESTED as JobCandidateStatus,
  JobCandidateStatuses.PASSIVE_NOT_LOOKING as JobCandidateStatus
];

export const jobCandidateEmploymentStatus: JobCandidateEmploymentStatus[] = [
  JobCandidateEmploymentStatuses.EMPLOYED as JobCandidateEmploymentStatus,
  JobCandidateEmploymentStatuses.NOT_EMPLOYED as JobCandidateEmploymentStatus,
  JobCandidateEmploymentStatuses.STUDENT as JobCandidateEmploymentStatus
];

export const enum JobCandidateFields {
  ID = 'id',
  UUID = 'uuid',
  NANO_ID = 'nanoId',
  NAME = 'name',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  PHONE = 'phone',
  LINKEDIN_URL = 'linkedinUrl',
  ARRANGEMENT = 'arrangement',
  JOB_TITLE = 'jobTitle',
  SEARCH_TYPE = 'searchType',
  SEARCH_TYPE_LABEL = 'searchTypeLabel',
  PERSONAL_PHONE = 'personalPhone',
  PERSONAL_EMAIL = 'personalEmail',
  COMPANY_PHONE = 'companyPhone',
  COMPANY_EMAIL = 'companyEmail',
  STATUS = 'status',
  IMAGE_ID = 'imageId',
  IMAGE = 'image',
  COMPANY_ID = 'companyId',
  COMPANY = 'company',
  GEOGRAPHY = 'geography',
  CITY_ID = 'cityId',
  CITY = 'city',
  STATE_ID = 'stateId',
  STATE = 'state',
  CITY_IDS = 'cityIds',
  CITIES = 'cities',
  SKILL_BADGES = 'skillBadges',
  SKILL_IDS = 'skillIds',
  JOB_CANDIDATE_EMPLOYMENTS = 'jobCandidateEmployments',
  JOB_CANDIDATE_CERTIFICATIONS = 'jobCandidateCertifications',
  JOB_CANDIDATE_EDUCATIONS = 'jobCandidateEducations',
  USER_ID = 'userId',
  USER = 'user',
  JOB_ID = 'jobId',
  JOB = 'job',
  JOB_RESUME_ID = 'jobResumeId',
  JOB_RESUME = 'jobResume',
  WORKFLOW_STAGE_ID = 'workflowStageId',
  WORKFLOW_STAGE = 'workflowStage',
  WORKFLOW_STAGE_TASK = 'workflowStageTask',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  DISQUALIFIED_AT = 'disqualifiedAt',
  DISQUALIFICATION_REASON = 'disqualificationReason',
  WORKFLOW_STAGE_TASK_SCHEDULED_AT = 'workflowStageTaskScheduledAt',
  NEW_WORKFLOW_STAGE_TASK_AT = 'newWorkflowStageTaskAt',
  VIEWED_AT = 'viewedAt',
  WORKFLOW_STAGE_TASK_APPLICATION_TYPE = 'workflowStageTaskApplicationType',
  DUE_DATE = 'dueDate',
  DESCRIPTION = 'description',
  INDUSTRY_IDS = 'industryIds',
  INDUSTRIES = 'industries',
  SPECIALIZATION_IDS = 'specializationIds',
  SPECIALIZATIONS = 'specializations',
  EMPLOYMENT_TYPES = 'employmentTypes',
  WORKPLACE_TYPES = 'workplaceTypes',
  PREFERRED_LOCATIONS = 'preferredLocations',
  PAID_TIME_OFF = 'paidTimeOff',
  MINIMUM_DESIRED_PAY = 'minimumDesiredPay',
  MAXIMUM_DESIRED_PAY = 'maximumDesiredPay',
  PRIMARY_JOB_FUNCTION = 'primaryJobFunction',
  WORK_AUTHORIZATION = 'workAuthorization',
  FUTURE_REQUIREMENTS = 'futureRequirements',
  PROFESSIONAL_EXPERIENCE = 'professionalExperience',
  TALENT_POOLS = 'talentPools',
  PHONE_NUMBERS = 'phoneNumbers',
  EMAIL_ADDRESSES = 'emailAddresses',
  EXPERIENCES = 'experiences',
  EDUCATIONS = 'educations',
  ASSIGN_TO_JOB = 'assignToJob',
  SOURCE = 'source',
  TAGS = 'tags',
  SKILLS = 'skills',
  OTHER_DETAILS = 'otherDetails',
  LIKED_JOB_CANDIDATES = 'likedJobCandidates',
  LIKED_JOB_CANDIDATE_IDS = 'likedJobCandidateIds',
  JOB_JOB_FUNCTION_ID = 'jobJobFunctionId',
  JOB_EXPERIENCE = 'jobExperience',
  JOB_GEOGRAPHY = 'jobGeography',
  CANDIDATE_SHARE_IDS = 'candidateShareIds',
  CANDIDATE_SHARES = 'candidateShares',
  PIPELINE_STATUS = 'pipelineStatus',
  MESSAGE = 'message',
  CANDIDATE_SUBMISSION_IDS = 'candidateSubmissionIds',
  CANDIDATE_SUBMISSIONS = 'candidateSubmissions',
  WORKFLOW_STAGE_NAME = 'workflowStageName',
  DEPARTMENT_ID = 'departmentId',
  EMPLOYMENT_STATUS = 'employmentStatus',
  PHONE_CALL_AVAILABILITY = 'phoneCallAvailability',

  NEW_JOB_START_AVAILABILITY = 'newJobStartAvailability',
  EMPLOYER_COUNTER_OFFER = 'employerCounterOffer',
  ACTIVELY_INTERVIEWING = 'activelyInterviewing',
  COUNTER_OFFER = 'counterOffer'
}
