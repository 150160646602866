import { Fragment, ReactNode, useEffect, useRef, useState } from 'react';
import { Box, Flex, Grid, GridItem, useDisclosure } from '@chakra-ui/react';

import { AlertMessage } from '../../../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../../../helpers/LoadingSkeleton';

import { JobCandidateNanoId } from '../../../../../jobCandidates/jobCandidatesTypes';

import { useCurrentUser } from '../../../../../../auth/hooks/useAuth';

import {
  ViewJobCandidateDetailsCandidateDetails,
  ViewJobCandidateDetailsCandidateDetailsJobCandidate
} from '../../../../../jobs/pages/ViewJobCandidateDetailsPage/components/ViewJobCandidateDetailsCandidateDetails';
import { HiringPortalSourcingRoutes } from '../../../../HirngPortalSourcingRoutes';
import {
  DepartmentId,
  DepartmentNanoId
} from '../../../../../departments/departmentsTypes';
import { HiringPortalTalentPoolCandidatesListContainer } from '../../../../components/HiringPortalTalentPoolCandidatesListContainer';
import { ErrorMessage, IsFetched } from '../../../../../../types';
import { useFetchJobByNanoId } from '../../../../../jobs/hooks/useFetchJobByNanoId';
import {
  fetchJobQuery,
  FetchJobResponse
} from '../../../../../jobs/queries/fetchJob.query';
import { JobsCache } from '../../../../../jobs/JobsCache';
import { FetchJobEnabled } from '../../../../../jobs/jobsTypes';
import { ViewJobCandidateDetailsJobDetailsJob } from '../../../../../jobs/pages/ViewJobCandidateDetailsPage/components/ViewJobCandidateDetailsJobDetails';
import { useScrollPosition } from '../../../../../../common/hooks/utils/useScrollPosition';
import { useViewJobCandidateDetailsSendAssessmentContext } from '../../../../../jobs/pages/ViewJobCandidateDetailsPage/contexts/ViewJobCandidateDetailsSendAssessmentContext';
import { ViewJobCandidateDetailsFloatingStageTasksManager } from '../../../../../jobs/pages/ViewJobCandidateDetailsPage/components/ViewJobCandidateDetailsFloatingStageTasksManager';
import { ViewJobCandidateDetailsSendAssessmentSidebar } from '../../../../../jobs/pages/ViewJobCandidateDetailsPage/components/ViewJobCandidateDetailsSendAssessmentSidebar';
import { SourcingTalentProfileHeaderMenu } from '../../../../../sourcing/pages/SourcingTalentProfilePage/components/SourcingTalentProfileHeaderMenu';
import { SourcingTalentProfileJobCandidateHeader } from '../../../../../sourcing/pages/SourcingTalentProfilePage/components/SourcingTalentProfileJobCandidateHeader';

interface HiringPortalTalentProfilePageContentProps {
  departmentNanoId: DepartmentNanoId;
  personNanoId: JobCandidateNanoId;
  children?: ReactNode;
  isBasePath?: boolean;
  requiresCredit: boolean;
  departmentErrorMessage: ErrorMessage;
  department: {
    nanoId: DepartmentNanoId;
    id: DepartmentId;
  };
  jobCandidateIsFetched: IsFetched;
  jobCandidateErrorMessage: ErrorMessage;
  jobCandidate: ViewJobCandidateDetailsCandidateDetailsJobCandidate;
  isJobDetailsPage?: boolean;
}

function HiringPortalTalentProfilePageContent({
  children,
  departmentNanoId,
  personNanoId,
  requiresCredit,
  isBasePath,
  department,
  departmentErrorMessage,
  jobCandidate,
  jobCandidateErrorMessage,
  jobCandidateIsFetched,
  isJobDetailsPage
}: HiringPortalTalentProfilePageContentProps) {
  const currentUser = useCurrentUser();
  const topScrollRef = useRef<HTMLDivElement>(null);

  const { job, jobIsFetched } = useFetchJobByNanoId<FetchJobResponse>({
    jobNanoId: jobCandidate?.job?.nanoId,
    query: fetchJobQuery,
    enabled: !!jobCandidate?.job?.nanoId as FetchJobEnabled,
    cacheKey: JobsCache.showJobCacheKey(jobCandidate?.job?.nanoId)
  });

  const [totalProspects, setTotalProspects] = useState<number | undefined>();

  const {
    isOpen: isBioOutsideViewport,
    onOpen: setBioOutsideViewport,
    onClose: setBioInsideViewport
  } = useDisclosure();

  const bioContainerRef = useScrollPosition({
    setIsInsideViewport: setBioInsideViewport,
    setIsOutsideViewport: setBioOutsideViewport
  });

  const { isSendEmailAssessmentOpen } =
    useViewJobCandidateDetailsSendAssessmentContext();

  const isRightSidebarOpen = isSendEmailAssessmentOpen;

  useEffect(() => {
    if (isBasePath) {
      setBioInsideViewport();

      if (topScrollRef.current) {
        topScrollRef.current.scrollIntoView({
          block: 'start'
        });
      }
    }
  }, [isBasePath, jobCandidate, setBioInsideViewport]);

  useEffect(() => {
    isRightSidebarOpen &&
      topScrollRef.current &&
      topScrollRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
  }, [isRightSidebarOpen]);

  return (
    <Fragment>
      <Box
        minH="100%"
        pos="relative"
        transitionDuration="fast"
        w={isRightSidebarOpen ? 'calc(100% - 400px)' : '100%'}
      >
        <Box
          h={10}
          w="full"
          opacity={0}
          pos="absolute"
          ref={topScrollRef}
          pointerEvents="none"
          cursor="not-allowed"
          zIndex={999}
        />

        <Flex minH="100%" flexDir="column">
          <SourcingTalentProfileHeaderMenu
            hasScrolled={isJobDetailsPage || isBioOutsideViewport}
            requiresCredit={requiresCredit}
            jobCandidate={jobCandidate}
            job={job as ViewJobCandidateDetailsJobDetailsJob}
            jobIsFetched={jobIsFetched}
            isHiringPortalPage
            totalProspects={totalProspects}
            departmentNanoId={departmentNanoId}
          />

          <Grid
            gap={2}
            templateColumns={{
              base: isRightSidebarOpen ? '1fr' : '290px 1fr',
              xl: isRightSidebarOpen ? '1fr' : '320px 1fr'
            }}
          >
            {jobCandidateErrorMessage || departmentErrorMessage ? (
              <GridItem colSpan={2}>
                <AlertMessage
                  message={jobCandidateErrorMessage || departmentErrorMessage}
                />
              </GridItem>
            ) : null}
            {isRightSidebarOpen ? null : (
              <HiringPortalTalentPoolCandidatesListContainer
                personNanoId={personNanoId}
                department={department}
                currentUser={currentUser}
                jobCandidateRoute={HiringPortalSourcingRoutes.viewPerson}
                updateProspectsCount={setTotalProspects}
              />
            )}
            <LoadingSkeleton loaded={jobCandidateIsFetched}>
              {jobCandidate ? (
                <Box
                  pl={isRightSidebarOpen ? { base: 4, xl: 12 } : undefined}
                  pr={isRightSidebarOpen ? { base: 4, xl: 6 } : undefined}
                  pb={isRightSidebarOpen ? 24 : 4}
                  pos="relative"
                  transform="auto"
                >
                  <Box
                    pos="relative"
                    transform="auto"
                    transitionDuration="fast"
                    bg={isRightSidebarOpen ? 'primary.50' : 'transparent'}
                    borderRadius={isRightSidebarOpen ? 'base' : undefined}
                    border={isRightSidebarOpen ? '1px solid' : undefined}
                    borderColor={
                      isRightSidebarOpen ? 'primary.200' : 'transparent'
                    }
                  >
                    {isRightSidebarOpen && (
                      <Box
                        w="full"
                        h="full"
                        opacity={0}
                        pos="absolute"
                        cursor="not-allowed"
                        zIndex={999}
                      />
                    )}

                    <Box
                      transitionDuration="fast"
                      px={isRightSidebarOpen ? 6 : 0}
                      pb={isRightSidebarOpen ? 6 : 0}
                      pos="relative"
                      transform="auto"
                    >
                      <Box
                        h={6}
                        top={20}
                        zIndex={99}
                        opacity={0}
                        pos="absolute"
                        pointerEvents="none"
                        ref={bioContainerRef}
                        w="full"
                      />

                      {isJobDetailsPage ? null : (
                        <Box py={4}>
                          <SourcingTalentProfileJobCandidateHeader
                            jobCandidate={jobCandidate}
                          />
                        </Box>
                      )}

                      <ViewJobCandidateDetailsCandidateDetails
                        job={jobCandidate.job || job}
                        isBasePath={isBasePath}
                        isHiringPortalPath
                        prospect
                        jobCandidate={jobCandidate}
                        requiresCredit={requiresCredit}
                        departmentNanoId={departmentNanoId}
                        isJobDetailsPage={isJobDetailsPage}
                      >
                        {children}
                      </ViewJobCandidateDetailsCandidateDetails>
                    </Box>
                  </Box>
                </Box>
              ) : null}
            </LoadingSkeleton>
          </Grid>
        </Flex>

        {job && jobCandidate ? (
          <ViewJobCandidateDetailsFloatingStageTasksManager
            hasScrolled={isBioOutsideViewport}
            jobCandidateNanoId={jobCandidate?.nanoId}
            jobCandidate={jobCandidate}
            job={job}
          />
        ) : null}
      </Box>

      {job && (
        <>
          {isRightSidebarOpen ? (
            <ViewJobCandidateDetailsSendAssessmentSidebar
              job={job}
              jobCandidateNanoId={personNanoId}
            />
          ) : null}
        </>
      )}
    </Fragment>
  );
}

export default HiringPortalTalentProfilePageContent;
