import {
  HStack,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useDisclosure
} from '@chakra-ui/react';

import { JobCandidateDetailsJobCandidate } from '../../ViewJobCandidateDetailsPage.types';
import { ViewJobCandidateDetailsJobDetailsJob } from '../ViewJobCandidateDetailsJobDetails';
import { usePaginatedJobJobCandidates } from '../../../../../jobJobCandidates/hooks/usePaginatedJobJobCandidates';
import {
  fetchJobJobCandidatesQuery,
  FetchJobJobCandidatesResponse
} from '../../../../../jobJobCandidates/queries/fetchJobJobCandidates.query';
import { JobJobCandidatesCache } from '../../../../../jobJobCandidates/JobJobCandidatesCache';
import {
  FetchJobJobCandidatesFilters,
  JobJobCandidateFields
} from '../../../../../jobJobCandidates/jobJobCandidatesTypes';
import { ChevronDownIcon } from '../../../../../../icons/ChevronDownIcon';
import { Heading } from '../../../../../../helpers/Heading';
import { renderJobTitle } from '../../../../utils/renderJobTitle';
import { ViewJobCandidateDetailsHeaderMenuJobsJobItem } from '../ViewJobCandidateDetailsHeaderMenuJobsJobItem';
import { JobNanoId } from '../../../../jobsTypes';

interface ViewJobCandidateDetailsHeaderMenuJobsProps {
  jobCandidate: JobCandidateDetailsJobCandidate;
  job: ViewJobCandidateDetailsJobDetailsJob;
  jobDetailsRoute: (jobNanoId: JobNanoId) => string;
}

export default function ViewJobCandidateDetailsHeaderMenuJobs({
  jobCandidate,
  job,
  jobDetailsRoute
}: ViewJobCandidateDetailsHeaderMenuJobsProps) {
  const { jobJobCandidates, jobJobCandidatesTotalCount } =
    usePaginatedJobJobCandidates<FetchJobJobCandidatesResponse>({
      query: fetchJobJobCandidatesQuery,
      cacheKey: JobJobCandidatesCache.jobCandidateListCacheKey(jobCandidate.id),
      initialFilters: {
        [JobJobCandidateFields.JOB_CANDIDATE_ID]: {
          operator: 'eq',
          value: jobCandidate.id
        }
      } as unknown as FetchJobJobCandidatesFilters
    });

  const { isOpen, onToggle, onClose } = useDisclosure();

  return (
    <Popover isOpen={isOpen} onClose={onClose} isLazy>
      <PopoverTrigger>
        <HStack spacing={0.5}>
          <Heading
            level="h2"
            fontSize="md"
            fontWeight="semibold"
            lineHeight={1.5}
            noOfLines={1}
            wordBreak="break-all"
            sx={{
              span: {
                display: '-webkit-box',
                '-webkit-box-orient': 'vertical',
                '-webkit-line-clamp': 'var(--chakra-line-clamp)'
              }
            }}
          >
            {renderJobTitle(job)}
          </Heading>

          {jobJobCandidatesTotalCount > 0 ? (
            <ChevronDownIcon onClick={onToggle} cursor="pointer" />
          ) : null}
        </HStack>
      </PopoverTrigger>

      <Portal>
        <PopoverContent
          minW="250px"
          maxW="350px"
          p={3}
          maxH={60}
          overflow="hidden"
        >
          {jobJobCandidates?.map((jobJobCandidate) => (
            <ViewJobCandidateDetailsHeaderMenuJobsJobItem
              key={jobJobCandidate.id}
              jobJobCandidateId={jobJobCandidate.id}
              jobJobCandidateJob={jobJobCandidate.job}
              jobCandidateId={jobCandidate.id}
              jobDetailsRoute={jobDetailsRoute}
            />
          ))}
        </PopoverContent>
      </Portal>
    </Popover>
  );
}
