import { useDisclosure } from '@chakra-ui/react';
import map from 'lodash/map';
import uniqBy from 'lodash/uniqBy';
import { Button } from '../../../../../../helpers/Button';
import { PlusIcon } from '../../../../../../icons/PlusIcon';
import { JobsCache } from '../../../../JobsCache';
import { ViewJobCandidateAddToJobItem } from '../../../../components/modals/ViewJobCandidateModal/tabs/ViewJobCandidateModalProfileTab/components/ViewJobCandidateAddToJob/ViewJobCandidateAddToJob';
import {
  FetchJobsCountType,
  FetchJobsFilters,
  FetchJobsSort,
  JobFields,
  JobId
} from '../../../../jobsTypes';
import { AddCandidateToJob } from '../../../ViewJobCandidatesPage/helpers/AddCandidateToJob';
import { JobCandidateDetailsJobCandidate } from '../../ViewJobCandidateDetailsPage.types';
import { usePaginatedJobs } from '../../../../hooks/usePaginatedJobs';
import { jobsInitialPageSize } from '../../../../jobs.data';
import { fetchJobQuery } from '../../../../queries/fetchJob.query';
import {
  FetchJobsResponse,
  fetchJobsQuery
} from '../../../../queries/fetchJobs.query';
import { useCreateJobJobCandidate } from '../../../../../jobJobCandidates/hooks/useCreateJobJobCandidate';
import { JobJobCandidatesCache } from '../../../../../jobJobCandidates/JobJobCandidatesCache';
import {
  fetchJobJobCandidatesQuery,
  FetchJobJobCandidatesResponse
} from '../../../../../jobJobCandidates/queries/fetchJobJobCandidates.query';
import { usePaginatedJobJobCandidates } from '../../../../../jobJobCandidates/hooks/usePaginatedJobJobCandidates';
import {
  FetchJobJobCandidatesFilters,
  JobJobCandidateFields
} from '../../../../../jobJobCandidates/jobJobCandidatesTypes';

interface ViewJobCandidateDetailsHeaderMenuAddJobProps {
  jobCandidate: JobCandidateDetailsJobCandidate;
}

export default function ViewJobCandidateDetailsHeaderMenuAddJob({
  jobCandidate
}: ViewJobCandidateDetailsHeaderMenuAddJobProps) {
  const {
    isOpen: isOpenAddToJob,
    onToggle: onToggleAddToJob,
    onClose: onCloseAddToJob
  } = useDisclosure();

  const { jobJobCandidates, jobJobCandidatesIsLoading } =
    usePaginatedJobJobCandidates<FetchJobJobCandidatesResponse>({
      query: fetchJobJobCandidatesQuery,
      cacheKey: JobJobCandidatesCache.jobCandidateListCacheKey(jobCandidate.id),
      initialFilters: {
        [JobJobCandidateFields.JOB_CANDIDATE_ID]: {
          operator: 'eq',
          value: jobCandidate.id
        }
      } as unknown as FetchJobJobCandidatesFilters
    });

  const { jobs: candidateJobs, jobsIsLoading } =
    usePaginatedJobs<FetchJobsResponse>({
      cacheKey: JobsCache.jobJobCandidateIndexCacheKey(jobCandidate.id),
      query: fetchJobsQuery,
      countType: 'exact' as FetchJobsCountType,
      fetchItemCacheKey: JobsCache.showCacheKey(),
      itemQuery: fetchJobQuery,
      initialFilters: {
        [JobFields.JOB_CANDIDATE_ID]: {
          operator: 'eq',
          value: jobCandidate.id
        }
      } as unknown as FetchJobsFilters,
      initialSort: {
        [JobFields.NAME]: { ascending: true }
      } as unknown as FetchJobsSort,
      initialPageSize: jobsInitialPageSize
    });

  const {
    createJobJobCandidate,
    createJobJobCandidateErrorMessage,
    createJobJobCandidateIsLoading
  } = useCreateJobJobCandidate({
    cacheKeys: [JobJobCandidatesCache.jobCandidateListCacheKey(jobCandidate.id)]
  });

  const onAddToJob = async (job: ViewJobCandidateAddToJobItem) => {
    await createJobJobCandidate({
      jobCandidateId: jobCandidate.id,
      jobId: job.id as JobId
    });
    onCloseAddToJob();
  };

  const allJobs = map(jobJobCandidates, (jjc) => jjc.job);
  const jobs = uniqBy([...candidateJobs, ...allJobs], 'id');

  return (
    <AddCandidateToJob
      isOpen={isOpenAddToJob}
      onClose={onCloseAddToJob}
      onAddToJob={onAddToJob}
      offset={[0, 8]}
      addedJobs={jobs}
      placement="bottom-start"
      addToJobErrorMessage={createJobJobCandidateErrorMessage}
      addToJobIsLoading={createJobJobCandidateIsLoading}
    >
      <Button
        h={8}
        size="medium"
        display="flex"
        color="primary.500"
        hierarchy="unstyled"
        disabled={jobsIsLoading || jobJobCandidatesIsLoading}
        onClick={onToggleAddToJob}
        leftIcon={<PlusIcon w={5} h={5} fill="primary.500" />}
      >
        Add job
      </Button>
    </AddCandidateToJob>
  );
}
