import {
  FetchJobJobCandidatesCacheKey,
  FetchJobJobCandidateCacheKey,
  DeleteJobJobCandidateErrorMessage,
  DeleteJobJobCandidateIsLoading,
  DeleteJobJobCandidateID
} from '../../jobJobCandidatesTypes';

import { useDeleteItem } from '../../../common/hooks/useDeleteItem';

import {
  JobJobCandidatesBffRequests,
  DeleteJobJobCandidateErrorResponse
} from '../../JobJobCandidatesBffRequests';

interface DeleteJobJobCandidateOptions {
  jobJobCandidateId: DeleteJobJobCandidateID;
  cacheKeys?: (FetchJobJobCandidatesCacheKey | FetchJobJobCandidateCacheKey)[];
}

function useDeleteJobJobCandidate({
  jobJobCandidateId,
  cacheKeys = []
}: DeleteJobJobCandidateOptions) {
  const {
    deleteItemData,
    deleteItemError,
    deleteItemErrorMessage,
    deleteItemIsLoading,
    deleteItem,
    deleteItemReset
  } = useDeleteItem<unknown, DeleteJobJobCandidateErrorResponse>({
    mutationFn: () =>
      JobJobCandidatesBffRequests.deleteJobJobCandidate(jobJobCandidateId),
    cacheKeys
  });

  return {
    deleteJobJobCandidateData: deleteItemData,
    deleteJobJobCandidateError: deleteItemError,
    deleteJobJobCandidateErrorMessage:
      deleteItemErrorMessage as DeleteJobJobCandidateErrorMessage,
    deleteJobJobCandidateIsLoading:
      deleteItemIsLoading as DeleteJobJobCandidateIsLoading,
    deleteJobJobCandidate: deleteItem,
    deleteJobJobCandidateReset: deleteItemReset
  };
}

export default useDeleteJobJobCandidate;
