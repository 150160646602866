import { Collapse, Flex, HStack, Stack, useDisclosure } from '@chakra-ui/react';
import { Dictionary, NumericDictionary } from 'lodash';
import filter from 'lodash/filter';
import find from 'lodash/find';
import first from 'lodash/first';
import flatMap from 'lodash/flatMap';
import includes from 'lodash/includes';
import last from 'lodash/last';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import size from 'lodash/size';
import slice from 'lodash/slice';
import some from 'lodash/some';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import { Key, ReactNode, useCallback } from 'react';
import { Heading } from '../../../../../../../../helpers/Heading';
import { LoadingSkeleton } from '../../../../../../../../helpers/LoadingSkeleton';
import { Text } from '../../../../../../../../helpers/Text';
import { CheckIcon } from '../../../../../../../../icons/CheckIcon';
import {
  DateTimeType,
  DateUtils
} from '../../../../../../../../utils/DateUtils';
import { FetchEmploymentsResponse } from '../../../../../../../employments/queries/fetchEmployments.query';
import { JobResumeRecruiterId } from '../../../../../../../jobResumeRecruiters/jobResumeRecruitersTypes';
import { workAuthorizationOptionsHash } from '../../../../../../../jobs/jobs.data';
import useEmploymentData from '../../../../../../../jobs/pages/ViewJobCandidateDetailsPage/hooks/useEmploymentData/useEmploymentData';

type EmploymentFunctionsType = {
  name: string;
  difference: string;
  dataDisplayed: ReactNode;
  noOfYears: number;
}[];

interface CareerSiteApplyProfilePageOverviewQualificationsProps {
  jobResumeRecruiterId: JobResumeRecruiterId;
  workAuthorization: string;
}

function CareerSiteApplyProfilePageOverviewQualifications({
  jobResumeRecruiterId,
  workAuthorization
}: CareerSiteApplyProfilePageOverviewQualificationsProps) {
  const {
    employments,
    partTimeEmployments,
    employmentsIsFetched,
    professionalExperience
  } = useEmploymentData({ jobResumeRecruiterId });

  const industryIdsList = uniq(
    flatMap(employments, (employment) => employment.industryIds)
  );

  const jobFunctionIdsList = uniq(
    flatMap(employments, (employment) => employment.jobFunctionIds)
  );

  const specializationIdsList = uniq(
    flatMap(employments, (employment) => employment.specializationIds)
  );

  const employmentFunctions = useCallback<
    (
      type: 'industryIds' | 'specializationIds' | 'jobFunctionIds',
      typeList: string[]
    ) => EmploymentFunctionsType
  >(
    (type, typeList) => {
      const employmentFunctionList = map(typeList, (typeItem) => {
        const filteredEmployments = filter(employments, (employment) => {
          const arr = employment[
            type as unknown as keyof FetchEmploymentsResponse
          ] as
            | Dictionary<string>
            | NumericDictionary<string>
            | null
            | undefined;

          return includes(arr, typeItem);
        });

        const today = DateUtils.startOfToday();

        const partTimeEmploymentYears =
          partTimeEmployments(filteredEmployments);

        const lastFilteredEmployment = last(filteredEmployments);

        const currentEmploymentExists = some(
          filteredEmployments,
          (employment) => employment.currently
        );

        const startDate = DateUtils.calendarDay(
          lastFilteredEmployment?.startDate as string
        );

        const latestEndDate = last(
          sortBy(filteredEmployments, ['endDate'])
        )?.endDate;

        const endDate = currentEmploymentExists
          ? today
          : DateUtils.calendarDay(latestEndDate as string);

        const yearDifference = (endDate
          ?.diff(startDate as DateTimeType, 'years')
          ?.toObject() || {}) as { years: number };

        const noOfYears = yearDifference.years - partTimeEmploymentYears;

        const months = Math.ceil(noOfYears * 12);

        const difference =
          typeof noOfYears === 'number' &&
          !Number.isNaN(noOfYears) &&
          typeof months === 'number' &&
          !Number.isNaN(months)
            ? noOfYears < 1
              ? `${months} mth${months === 1 ? '' : 's'}`
              : `${Math.ceil(noOfYears)} yr${
                  Math.ceil(noOfYears) === 1 ? '' : 's'
                }`
            : 'None';

        let employmentFunctionName = '';

        if (type === 'jobFunctionIds') {
          employmentFunctionName = find(
            lastFilteredEmployment?.jobFunctions,
            ({ id }) => id === typeItem
          )?.name as string;
        } else if (type === 'industryIds') {
          employmentFunctionName = find(
            lastFilteredEmployment?.industries,
            ({ id }) => id === typeItem
          )?.name as string;
        } else if (type === 'specializationIds') {
          employmentFunctionName = find(
            lastFilteredEmployment?.specializations,
            ({ id }) => id === typeItem
          )?.name as string;
        }

        const jobFunctionData = {
          name: employmentFunctionName,
          difference,
          dataDisplayed: (
            <HStack w="full" alignItems="flex-start">
              <Text flex={1}>{employmentFunctionName}</Text>

              <Text ml="auto" textStyle="body1Medium" color="gray.900">
                ({difference})
              </Text>
            </HStack>
          ),
          noOfYears: noOfYears
        };

        return jobFunctionData;
      });

      const filteredEmploymentFunctionList = orderBy(
        filter(employmentFunctionList, ({ name }) => name),
        ['noOfYears'],
        'desc'
      ) as EmploymentFunctionsType;

      return filteredEmploymentFunctionList;
    },
    [employments, partTimeEmployments]
  );

  return (
    <Stack p={4} gap={3} flex={1} bg="white" borderRadius="base">
      <Flex gap={2} alignItems="center">
        <Heading
          m={0}
          p={0}
          level="h4"
          fontSize="md"
          lineHeight={1}
          fontWeight="semibold"
        >
          Qualifications
        </Heading>
      </Flex>

      <Stack spacing={5} color="gray.600">
        {professionalExperience && (
          <OverviewQualificationItem
            label="Professional Experience"
            value={professionalExperience}
            isFetched={employmentsIsFetched}
          />
        )}

        <OverviewQualificationItem
          label="Work Authorization"
          value={workAuthorizationOptionsHash[workAuthorization]}
        />

        <OverviewQualificationItem
          label="Job Functions"
          value={map(
            employmentFunctions('jobFunctionIds', jobFunctionIdsList),
            (jobFunction) => jobFunction.dataDisplayed
          )}
          withSeeMore
          isFetched={employmentsIsFetched}
        />

        <OverviewQualificationItem
          label="Specializations"
          value={map(
            employmentFunctions('specializationIds', specializationIdsList),
            (specialization) => specialization.dataDisplayed
          )}
          withSeeMore
          isFetched={employmentsIsFetched}
        />

        <OverviewQualificationItem
          label="Industry Experiences"
          value={map(
            employmentFunctions('industryIds', industryIdsList),
            (industry) => industry.dataDisplayed
          )}
          withSeeMore
          isFetched={employmentsIsFetched}
        />
      </Stack>
    </Stack>
  );
}

export default CareerSiteApplyProfilePageOverviewQualifications;

interface OverviewQualificationItemProps {
  label: string;
  value: string | string[] | ReactNode[];
  separator?: string;
  matchRole?: boolean;
  withSeeMore?: boolean;
  isFetched?: boolean;
}

const OverviewQualificationItem = ({
  label,
  value,
  matchRole,
  withSeeMore,
  separator = ',',
  isFetched = true
}: OverviewQualificationItemProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const valuesFromSecondItem = slice(value, 1, size(value));

  return (
    <Stack>
      <HStack>
        <Text
          noOfLines={1}
          color="gray.800"
          wordBreak="break-all"
          textStyle="body1Medium"
        >
          {label}
        </Text>
      </HStack>

      <LoadingSkeleton loaded={isFetched} count={1}>
        <Flex
          gap={withSeeMore ? 1 : 0}
          flexDirection="column"
          position="relative"
        >
          <Flex alignItems="center" gap={2}>
            {withSeeMore ? (
              <Text
                w="full"
                as="section"
                color="gray.600"
                textStyle="body1Regular"
              >
                {first(value)}
              </Text>
            ) : (
              <Text as="section" color="gray.600" textStyle="body1Regular">
                {typeof value === 'string'
                  ? value
                  : value?.map((item, index) => (
                      <Flex as="span" display="inline-block" key={index}>
                        {item}
                        {index !== value.length - 1 && <>{separator}&nbsp;</>}
                      </Flex>
                    ))}
              </Text>
            )}

            {matchRole && <CheckIcon />}
          </Flex>

          {withSeeMore && typeof value !== 'string' && size(value) > 1 ? (
            <Flex flexDir="column" onMouseLeave={onClose}>
              <Flex
                as={Collapse}
                startingHeight={0}
                in={isOpen}
                unmountOnExit={true}
                animateOpacity
                dir="column"
                alignItems="start"
              >
                <Flex gap={2} flex={1} flexDir="column" mt={1}>
                  {valuesFromSecondItem?.map((item) => (
                    <Text
                      as="span"
                      textStyle="body1Regular"
                      color="gray.600"
                      key={item as Key}
                    >
                      {item}
                    </Text>
                  ))}
                </Flex>
              </Flex>

              <Flex
                as={Collapse}
                startingHeight={0}
                in={!isOpen}
                unmountOnExit={true}
                animateOpacity
              >
                <Text
                  color="primary.500"
                  textStyle="body1Regular"
                  cursor="pointer"
                  width="fit-content"
                  onMouseEnter={onOpen}
                >
                  See more
                </Text>
              </Flex>
            </Flex>
          ) : null}
        </Flex>
      </LoadingSkeleton>
    </Stack>
  );
};
