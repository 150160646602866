import { FieldBrand, ItemRecordID } from '../../../types';

import { UserUuid } from '../../users/usersTypes';

export type JobJobCandidateRecordId = FieldBrand<
  ItemRecordID,
  'JobJobCandidateRecordId'
>;

export type JobJobCandidateUserUuid = FieldBrand<
  UserUuid,
  'JobJobCandidateUserUuid'
>;

export const enum JobJobCandidateIndexFields {
  ID = 'id',
  RECORD_ID = 'recordId',
  UUID = 'uuid',
  CREATED_AT = 'createdAt',

  USER_UUID = 'userUuid'
}
