import { usePaginatedItems } from '../../../common/hooks/usePaginatedItems';

import {
  FetchJobJobCandidatesQuery,
  FetchJobJobCandidatesCacheKey,
  FetchJobJobCandidatesTotalCount,
  FetchJobJobCandidatesPage,
  FetchJobJobCandidatesPageSize,
  FetchJobJobCandidatesError,
  FetchJobJobCandidatesErrorMessage,
  FetchJobJobCandidatesIsLoading,
  FetchJobJobCandidatesIsFetched,
  FetchJobJobCandidatesIsPlaceholderData,
  FetchJobJobCandidatesEnabled,
  FetchJobJobCandidatesPrefetchNextPage,
  FetchJobJobCandidatesCountType,
  FetchJobJobCandidatesFilters,
  FetchJobJobCandidatesSort,
  FetchJobJobCandidateID,
  FetchJobJobCandidateQuery,
  FetchJobJobCandidateCacheKey
} from '../../jobJobCandidatesTypes';

import { JobJobCandidatesBffRequests } from '../../JobJobCandidatesBffRequests';

interface PaginatedJobJobCandidatesBaseOptions<
  PaginatedJobJobCandidatesResponseType
> {
  cacheKey: FetchJobJobCandidatesCacheKey;
  query: FetchJobJobCandidatesQuery;
  countType?: FetchJobJobCandidatesCountType;
  initialPage?: FetchJobJobCandidatesPage;
  initialPageSize?: FetchJobJobCandidatesPageSize;
  initialFilters?: FetchJobJobCandidatesFilters;
  initialSort?: FetchJobJobCandidatesSort;
  enabled?: FetchJobJobCandidatesEnabled;
  prefetchNextPage?: FetchJobJobCandidatesPrefetchNextPage;
  placeholderJobJobCandidates?: PaginatedJobJobCandidatesResponseType[];
  placeholderJobJobCandidatesCount?: FetchJobJobCandidatesTotalCount;
  placeholderData?: () => PaginatedJobJobCandidatesResponse<PaginatedJobJobCandidatesResponseType>;
}

interface PaginatedJobJobCandidatesOptionsWithoutItem {
  fetchItemCacheKey?: never;
  itemQuery?: never;
}

interface PaginatedJobJobCandidatesOptionsWithItem {
  fetchItemCacheKey: FetchJobJobCandidateCacheKey;
  itemQuery: FetchJobJobCandidateQuery;
}

type PaginatedJobJobCandidatesOptions<PaginatedJobJobCandidatesResponseType> =
  PaginatedJobJobCandidatesBaseOptions<PaginatedJobJobCandidatesResponseType> &
    (
      | PaginatedJobJobCandidatesOptionsWithoutItem
      | PaginatedJobJobCandidatesOptionsWithItem
    );

interface PaginatedJobJobCandidatesResponse<
  PaginatedJobJobCandidatesResponseType
> {
  data: PaginatedJobJobCandidatesResponseType[];
  totalCount: FetchJobJobCandidatesTotalCount;
}

function usePaginatedJobJobCandidates<PaginatedJobJobCandidatesResponseType>({
  cacheKey,
  query,
  countType,
  initialPage,
  initialPageSize,
  initialFilters,
  initialSort,
  enabled,
  prefetchNextPage,
  placeholderJobJobCandidates,
  placeholderJobJobCandidatesCount,
  placeholderData,
  fetchItemCacheKey,
  itemQuery
}: PaginatedJobJobCandidatesOptions<PaginatedJobJobCandidatesResponseType>) {
  const {
    items,
    itemsTotalCount,
    itemsError,
    itemsErrorMessage,
    itemsIsFetched,
    itemsIsLoading,
    itemsIsPlaceholderData,
    itemsCurrentPage,
    itemsCurrentPageSize,
    itemsCurrentFilters,
    itemsCurrentSort,
    fetchItems,
    paginateItems,
    changeItemsPageSize,
    filterItems,
    changeItemsFilters,
    clearItemsFilters,
    sortItems,
    prefetchItems,
    prefetchItem
  } = usePaginatedItems<PaginatedJobJobCandidatesResponseType>({
    cacheKey,
    initialPage,
    initialPageSize,
    initialFilters,
    initialSort,
    enabled,
    prefetchNextPage,
    placeholderItems: placeholderJobJobCandidates,
    placeholderItemsCount: placeholderJobJobCandidatesCount,
    placeholderData,
    queryFn: (params) =>
      JobJobCandidatesBffRequests.fetchJobJobCandidates<PaginatedJobJobCandidatesResponseType>(
        {
          query,
          countType,
          page: params.page as FetchJobJobCandidatesPage,
          pageSize: params.pageSize as FetchJobJobCandidatesPageSize,
          filters: params.filters as FetchJobJobCandidatesFilters,
          sort: params.sort as FetchJobJobCandidatesSort
        }
      ),
    ...(itemQuery
      ? {
          fetchItemCacheKey,
          queryItemFn: (itemId) =>
            JobJobCandidatesBffRequests.fetchJobJobCandidate(
              itemId as FetchJobJobCandidateID,
              {
                query: itemQuery
              }
            )
        }
      : {})
  });

  return {
    jobJobCandidates: items as PaginatedJobJobCandidatesResponseType[],
    jobJobCandidatesTotalCount:
      itemsTotalCount as FetchJobJobCandidatesTotalCount,
    jobJobCandidatesError: itemsError as FetchJobJobCandidatesError,
    jobJobCandidatesErrorMessage:
      itemsErrorMessage as FetchJobJobCandidatesErrorMessage,
    jobJobCandidatesIsLoading: itemsIsLoading as FetchJobJobCandidatesIsLoading,
    jobJobCandidatesIsFetched: itemsIsFetched as FetchJobJobCandidatesIsFetched,
    jobJobCandidatesIsPlaceholderData:
      itemsIsPlaceholderData as FetchJobJobCandidatesIsPlaceholderData,
    jobJobCandidatesCurrentPage: itemsCurrentPage as FetchJobJobCandidatesPage,
    jobJobCandidatesCurrentPageSize:
      itemsCurrentPageSize as FetchJobJobCandidatesPageSize,
    jobJobCandidatesCurrentFilters:
      itemsCurrentFilters as FetchJobJobCandidatesFilters,
    jobJobCandidatesCurrentSort: itemsCurrentSort as FetchJobJobCandidatesSort,
    fetchJobJobCandidates: fetchItems,
    paginateJobJobCandidates: paginateItems,
    changeJobJobCandidatesPageSize: changeItemsPageSize,
    filterJobJobCandidates: filterItems,
    changeJobJobCandidatesFilters: changeItemsFilters,
    clearJobJobCandidatesFilters: clearItemsFilters,
    sortJobJobCandidates: sortItems,
    prefetchJobJobCandidates: prefetchItems,
    prefetchJobJobCandidate: prefetchItem
  };
}

export default usePaginatedJobJobCandidates;
