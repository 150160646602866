import { FetchJobJobCandidatesCacheKey } from '../../jobJobCandidatesTypes';

import { useCreateItem } from '../../../common/hooks/useCreateItem';

import {
  JobJobCandidatesBffRequests,
  CreateJobJobCandidateRequestData,
  CreateJobJobCandidateErrorResponse
} from '../../JobJobCandidatesBffRequests';

interface CreateJobJobCandidateOptions {
  cacheKeys?: FetchJobJobCandidatesCacheKey[];
}

function useCreateJobJobCandidate({
  cacheKeys = []
}: CreateJobJobCandidateOptions) {
  const {
    createItemData,
    createItemError,
    createItemErrorMessage,
    createItemIsLoading,
    createItem,
    createItemReset
  } = useCreateItem<
    CreateJobJobCandidateRequestData,
    CreateJobJobCandidateErrorResponse
  >({
    mutationFn: (queryInput) =>
      JobJobCandidatesBffRequests.createJobJobCandidate(queryInput),
    cacheKeys
  });

  return {
    createJobJobCandidateData: createItemData,
    createJobJobCandidateError: createItemError,
    createJobJobCandidateErrorMessage: createItemErrorMessage,
    createJobJobCandidateIsLoading: createItemIsLoading,
    createJobJobCandidate: createItem,
    createJobJobCandidateReset: createItemReset
  };
}

export default useCreateJobJobCandidate;
