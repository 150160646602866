import { useDisclosure } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo
} from 'react';

type ViewJobCandidateDetailsContextType = {
  openProfileOverview: () => void;
  closeProfileOverview: () => void;
  toggleProfileOverview: () => void;
  isProfileOverviewOpen: boolean;
};

type ViewJobCandidateDetailsProviderProps = {
  children: ReactNode;
};

const ViewJobCandidateDetailsContext =
  createContext<ViewJobCandidateDetailsContextType>({
    isProfileOverviewOpen: true,
    openProfileOverview: () => {
      //
    },
    closeProfileOverview: () => {
      //
    },
    toggleProfileOverview: () => {
      //
    }
  });

export function ViewJobCandidateDetailsProvider({
  children
}: ViewJobCandidateDetailsProviderProps) {
  const router = useRouter();

  const {
    onOpen: openProfileOverview,
    onClose: closeProfileOverview,
    onToggle: toggleProfileOverview,
    isOpen: isProfileOverviewOpen
  } = useDisclosure({
    defaultIsOpen: true
  });

  useEffect(() => {
    const handleRouteChange = () => {
      openProfileOverview();
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [openProfileOverview, router.events]);

  const values: ViewJobCandidateDetailsContextType = useMemo(
    () => ({
      openProfileOverview,
      closeProfileOverview,
      toggleProfileOverview,
      isProfileOverviewOpen
    }),
    [
      openProfileOverview,
      closeProfileOverview,
      toggleProfileOverview,
      isProfileOverviewOpen
    ]
  );

  return (
    <ViewJobCandidateDetailsContext.Provider value={values}>
      {children}
    </ViewJobCandidateDetailsContext.Provider>
  );
}

export const useViewJobCandidateDetailsContext = () =>
  useContext(ViewJobCandidateDetailsContext);
