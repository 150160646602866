import { Flex, HStack, Stack } from '@chakra-ui/react';
import find from 'lodash/find';
import startCase from 'lodash/startCase';
import { ReactNode } from 'react';
import { Heading } from '../../../../../../../../helpers/Heading';
import { Text } from '../../../../../../../../helpers/Text';
import { CheckIcon } from '../../../../../../../../icons/CheckIcon';
import { FormatNumber } from '../../../../../../../../utils/FormatNumber';
import { paidTimeOffOptions } from '../../../../../../../jobResumeRecruiters/JobResumeRecruiters.data';
import { CareerSiteApplyProfilePageJobResumeRecruiter } from '../../../../components/CareerSiteApplyProfilePage/CareerSiteApplyProfilePage.types';

interface CareerSiteApplyProfilePageOverviewMyNxmoovProps {
  jobResumeRecruiter: CareerSiteApplyProfilePageJobResumeRecruiter;
}

function CareerSiteApplyProfilePageOverviewMyNxmoov({
  jobResumeRecruiter
}: CareerSiteApplyProfilePageOverviewMyNxmoovProps) {
  const { minimumDesiredPay, maximumDesiredPay, paidTimeOff } =
    jobResumeRecruiter;

  let formattedSalary = '';

  if (minimumDesiredPay && maximumDesiredPay) {
    formattedSalary = `${FormatNumber.money(
      minimumDesiredPay
    )} - ${FormatNumber.money(maximumDesiredPay)} PA`;
  } else if (minimumDesiredPay) {
    formattedSalary = `${FormatNumber.money(minimumDesiredPay)} PA`;
  } else if (maximumDesiredPay) {
    formattedSalary = `${FormatNumber.money(maximumDesiredPay)} PA`;
  } else {
    formattedSalary = 'Salary not specified';
  }

  return (
    <Stack p={4} gap={3} flex={1} bg="white" borderRadius="base">
      <Flex gap={2} alignItems="center">
        <Heading
          m={0}
          p={0}
          level="h4"
          fontSize="md"
          lineHeight={1}
          fontWeight="semibold"
        >
          My Nxmoov
        </Heading>
      </Flex>

      <Stack spacing={4} color="gray.600">
        <OverviewPreferenceItem
          label="New position availbility"
          value={startCase(jobResumeRecruiter.newJobStartAvailability)}
        />

        <OverviewPreferenceItem
          label="Employer counter offer"
          value={startCase(jobResumeRecruiter.employerCounterOffer)}
        />

        <OverviewPreferenceItem
          label="Actively interviewing"
          value={startCase(jobResumeRecruiter.activelyInterviewing)}
        />

        <OverviewPreferenceItem
          label="Present offer"
          value={startCase(jobResumeRecruiter.counterOffer)}
        />

        <OverviewPreferenceItem
          label="Desired paid time off (PTO)"
          value={find(paidTimeOffOptions, { value: paidTimeOff })?.label}
        />

        <OverviewPreferenceItem
          label="Preferred salary"
          value={formattedSalary}
        />
      </Stack>
    </Stack>
  );
}

export default CareerSiteApplyProfilePageOverviewMyNxmoov;

interface OverviewPreferenceItem {
  label: string;
  value: ReactNode;
  isMatch?: boolean;
}

const OverviewPreferenceItem = ({
  label,
  value,
  isMatch
}: OverviewPreferenceItem) => (
  <Stack spacing={1}>
    <HStack>
      <Text
        noOfLines={1}
        color="gray.800"
        wordBreak="break-all"
        textStyle="body1Medium"
      >
        {label}
      </Text>

      {isMatch && <CheckIcon w={4} h={4} fill="green.600" />}
    </HStack>

    <Text textStyle="body1Regular" lineHeight={1.5}>
      {value || '--'}
    </Text>
  </Stack>
);
