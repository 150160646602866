import { GridItem } from '@chakra-ui/react';
import size from 'lodash/size';

import { usePaginatedJobCandidates } from '../../../../jobCandidates/hooks/usePaginatedJobCandidates';

import { JobCandidatesCache } from '../../../../jobCandidates/JobCandidatesCache';

import {
  FetchJobCandidatesCountType,
  FetchJobCandidatesFilters,
  JobCandidateFields,
  JobCandidateNanoId,
  JobCandidateSearchTypes
} from '../../../../jobCandidates/jobCandidatesTypes';
import { JobId, JobNanoId } from '../../../../jobs/jobsTypes';
import { UserId } from '../../../../users/usersTypes';
import {
  DepartmentId,
  DepartmentNanoId
} from '../../../../departments/departmentsTypes';

import {
  fetchJobCandidatesQuery,
  FetchJobCandidatesResponse
} from '../../../../jobCandidates/queries/fetchJobCandidates.query';

import { TalentPoolCandidatesList } from '../../lists/TalentPoolCandidatesList';
import { useCallback } from 'react';

interface TalentPoolCandidatesListContainerProps {
  currentUser: {
    id: UserId;
  };
  personNanoId?: JobCandidateNanoId;
  jobCandidateRoute: (
    departmentNanoId: DepartmentNanoId,
    personNanoId: JobCandidateNanoId
  ) => string;
  isDisabled?: boolean;
  fullHeight?: boolean;
  department?: {
    nanoId: DepartmentNanoId;
    id: DepartmentId;
  };
  job?: {
    nanoId: JobNanoId;
    id: JobId;
    department: {
      nanoId: DepartmentNanoId;
      id: DepartmentId;
    };
  };
  updateProspectsCount?: (count: number) => void;
}

function TalentPoolCandidatesListContainer({
  job,
  department,
  personNanoId,
  currentUser,
  jobCandidateRoute,
  isDisabled,
  fullHeight,
  updateProspectsCount
}: TalentPoolCandidatesListContainerProps) {
  const {
    jobCandidates,
    jobCandidatesIsFetched,
    jobCandidatesTotalCount,
    jobCandidatesErrorMessage,
    changeJobCandidatesFilters,
    filterJobCandidates
  } = usePaginatedJobCandidates<FetchJobCandidatesResponse>({
    query: fetchJobCandidatesQuery,
    cacheKey: JobCandidatesCache.indexCacheKey(),
    countType: 'exact' as FetchJobCandidatesCountType,
    initialFilters: {
      [JobCandidateFields.SEARCH_TYPE]: {
        operator: 'not.eq',
        value: JobCandidateSearchTypes.LEAD
      },
      ...(department || job?.department?.id
        ? {
            [JobCandidateFields.DEPARTMENT_ID]: {
              operator: 'eq',
              value: department?.id || job?.department?.id
            }
          }
        : {})
    } as unknown as FetchJobCandidatesFilters,
    onSuccess(data) {
      const count = size(data.data);
      updateProspectsCount?.(count);
    }
  });

  const filterCandidatesByDepartment = useCallback<
    (departmentId: DepartmentId) => void
  >(
    (departmentId) => {
      if (departmentId === 'all') {
        filterJobCandidates({
          [JobCandidateFields.SEARCH_TYPE]: {
            operator: 'not.eq',
            value: JobCandidateSearchTypes.LEAD
          }
        });
      } else {
        changeJobCandidatesFilters({
          [JobCandidateFields.DEPARTMENT_ID]: {
            operator: 'eq',
            value: departmentId
          }
        });
      }
    },
    [changeJobCandidatesFilters, filterJobCandidates]
  );

  return (
    <GridItem w="full" h="full" pos="relative" overflow="hidden">
      <TalentPoolCandidatesList
        jobCandidates={jobCandidates}
        jobCandidatesTotalCount={jobCandidatesTotalCount}
        jobCandidatesIsFetched={jobCandidatesIsFetched}
        jobCandidatesErrorMessage={jobCandidatesErrorMessage}
        department={department || job?.department}
        personNanoId={personNanoId}
        currentUser={currentUser}
        jobCandidateRoute={jobCandidateRoute}
        filterCandidatesByDepartment={filterCandidatesByDepartment}
        isDisabled={isDisabled}
        fullHeight={fullHeight}
      />
    </GridItem>
  );
}

export default TalentPoolCandidatesListContainer;
