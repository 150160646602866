import Lottie from 'lottie-react';
import React from 'react';
import animationData from './animationData.json';

const LogoIconLoop = ({ style }: { style?: React.CSSProperties }) => {
  return (
    <>
      {animationData && (
        <Lottie
          animationData={animationData}
          style={{
            height: '300px',
            width: '300px',
            ...(style || {})
          }}
        />
      )}
    </>
  );
};

export default LogoIconLoop;
