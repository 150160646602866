import { JobId, JobNanoId } from '../jobs/jobsTypes';

import {
  FetchJobCandidatesQuery,
  FetchJobCandidatesCountType,
  FetchJobCandidatesPage,
  FetchJobCandidatesPageSize,
  FetchJobCandidatesFilters,
  FetchJobCandidatesSort,
  FetchJobCandidateQuery,
  CreateJobCandidateError,
  UpdateJobCandidateError,
  DeleteJobCandidateError,
  JobCandidateId,
  JobCandidateNanoId,
  JobCandidateName,
  JobCandidateUserId,
  JobCandidateEmail,
  JobCandidatePhone,
  JobCandidateJobTitle,
  JobCandidateWorkflowStageId,
  JobCandidateWorkflowStageTaskApplicationType,
  MayBeJobCandidateWorkflowStageTaskScheduledAt,
  MayBeJobCandidateDisqualifiedAt,
  JobCandidateDisqualificationReason,
  JobCandidateUpdatedAt,
  JobCandidateArrangement,
  MayBeJobCandidateDueDate,
  JobCandidateSearchTypeLabel,
  MayBeJobCandidateViewedAt,
  MayBeJobCandidateNewWorkflowStageTaskAt,
  JobCandidatePrimaryJobFunction,
  JobCandidateWorkAuthorization,
  JobCandidateFutureRequirements,
  JobCandidateIndustryIds,
  JobCandidateSpecializationIds,
  JobCandidateProfessionalExperience,
  JobCandidateStatus,
  JobCandidateEmploymentTypes,
  JobCandidateWorkplaceTypes,
  JobCandidatePaidTimeOff,
  MayBeJobCandidateMinimumDesiredPay,
  MayBeJobCandidateMaximumDesiredPay,
  MayBeJobCandidateCityIds,
  JobCandidateMessage,
  RecruitJobCandidateError,
  RejectJobCandidateError,
  SaveJobCandidateError,
  RevertJobCandidatePipelineStatusError,
  ImportJobCandidateEmailsError
} from './jobCandidatesTypes';

import { TalentPoolNanoId } from '../talentPools/talentPoolsTypes';

import { BaseBffRequests } from '../BaseBffRequests';

import { JobCandidatesBffRoutes } from './JobCandidatesBffRoutes';

interface FetchJobCandidatesRequestParams {
  query: FetchJobCandidatesQuery;
  countType?: FetchJobCandidatesCountType;
  page?: FetchJobCandidatesPage;
  pageSize?: FetchJobCandidatesPageSize;
  filters?: FetchJobCandidatesFilters;
  sort?: FetchJobCandidatesSort;
}

interface FetchJobCandidateRequestParams {
  query: FetchJobCandidateQuery;
}

export interface CreateJobCandidateRequestData {
  nanoId: JobCandidateNanoId;
  jobId: JobId;
  userId: JobCandidateUserId;
}

export interface UpdateJobCandidateRequestData {
  name?: JobCandidateName;
  email?: JobCandidateEmail;
  phone?: JobCandidatePhone;
  jobTitle?: JobCandidateJobTitle;
  workflowStageId?: JobCandidateWorkflowStageId;
  workflowStageTaskId?: JobCandidateWorkflowStageId;
  workflowStageTaskApplicationType?: JobCandidateWorkflowStageTaskApplicationType;
  workflowStageTaskScheduledAt?: MayBeJobCandidateWorkflowStageTaskScheduledAt;
  newWorkflowStageTaskAt?: MayBeJobCandidateNewWorkflowStageTaskAt;
  viewedAt?: MayBeJobCandidateViewedAt;
  disqualifiedAt?: MayBeJobCandidateDisqualifiedAt;
  disqualificationReason?: JobCandidateDisqualificationReason;
  updatedAt?: JobCandidateUpdatedAt;
  arrangement?: JobCandidateArrangement;
  dueDate?: MayBeJobCandidateDueDate;
  searchTypeLabel?: JobCandidateSearchTypeLabel;
  primaryJobFunction?: JobCandidatePrimaryJobFunction;
  workAuthorization?: JobCandidateWorkAuthorization;
  futureRequirements?: JobCandidateFutureRequirements;
  industryIds?: JobCandidateIndustryIds;
  specializationIds?: JobCandidateSpecializationIds;
  professionalExperience?: JobCandidateProfessionalExperience;
  status?: JobCandidateStatus;
  employmentTypes?: JobCandidateEmploymentTypes;
  workplaceTypes?: JobCandidateWorkplaceTypes;
  paidTimeOff?: JobCandidatePaidTimeOff;
  minimumDesiredPay?: MayBeJobCandidateMinimumDesiredPay;
  maximumDesiredPay?: MayBeJobCandidateMaximumDesiredPay;
  cityIds?: MayBeJobCandidateCityIds;
}

export interface DeleteJobCandidateRequestData {
  jobCandidateNanoId: JobCandidateNanoId;
}

export interface AddCandidateToJobRequestData {
  jobNanoId: JobNanoId;
}

export interface AddCandidateToTalentPoolRequestData {
  talentPoolNanoId: TalentPoolNanoId;
}

export interface RemoveCandidateFromTalentPoolRequestData {
  talentPoolNanoId: TalentPoolNanoId;
}

export interface RecruitJobCandidateRequestData {
  message: JobCandidateMessage;
}

export interface RejectJobCandidateRequestData {
  message: JobCandidateMessage;
}

export interface SaveJobCandidateRequestData {
  message: JobCandidateMessage;
}

export interface CreateJobCandidateErrorResponse {
  message: CreateJobCandidateError;
}

export interface UpdateJobCandidateErrorResponse {
  message: UpdateJobCandidateError;
}

export interface DeleteJobCandidateErrorResponse {
  message: DeleteJobCandidateError;
}

export interface AddCandidateToJobErrorResponse {
  message: UpdateJobCandidateError;
}

export interface AddCandidateToTalentPoolErrorResponse {
  message: UpdateJobCandidateError;
}

export interface RemoveCandidateFromTalentPoolErrorResponse {
  message: UpdateJobCandidateError;
}

export interface RecruitJobCandidateErrorResponse {
  message: RecruitJobCandidateError;
}

export interface RejectJobCandidateErrorResponse {
  message: RejectJobCandidateError;
}

export interface SaveJobCandidateErrorResponse {
  message: SaveJobCandidateError;
}

export interface RevertJobCandidatePipelineStatusErrorResponse {
  message: RevertJobCandidatePipelineStatusError;
}

export interface ImportJobCandidateEmailsErrorResponse {
  message: ImportJobCandidateEmailsError;
}

export class JobCandidatesBffRequests extends BaseBffRequests {
  static fetchJobCandidates<T>(params: FetchJobCandidatesRequestParams) {
    return this.fetchItems<T>(
      JobCandidatesBffRoutes.fetchJobCandidatesRoute(),
      params
    );
  }

  static fetchJobCandidate<T>(
    jobCandidateId: JobCandidateId,
    params: FetchJobCandidateRequestParams
  ) {
    return this.fetchItem<T>(
      JobCandidatesBffRoutes.fetchJobCandidateRoute(jobCandidateId),
      params
    );
  }

  static fetchJobCandidateByNanoId<T>(
    jobCandidateNanoId: JobCandidateNanoId,
    params: FetchJobCandidateRequestParams
  ) {
    return this.fetchItemByNanoId<T>(
      JobCandidatesBffRoutes.fetchJobCandidateByNanoIdRoute(jobCandidateNanoId),
      params
    );
  }

  static createJobCandidate<T>(
    createJobCandidateData: CreateJobCandidateRequestData
  ) {
    return this.createItem<T, CreateJobCandidateRequestData>(
      JobCandidatesBffRoutes.createJobCandidateRoute(),
      createJobCandidateData
    );
  }

  static async updateJobCandidate(
    updateJobCandidateId: JobCandidateId,
    updateJobCandidateData: UpdateJobCandidateRequestData
  ) {
    const { data, error } = await this.updateItem<
      { data: UpdateJobCandidateRequestData; error: string | null },
      UpdateJobCandidateRequestData
    >(
      JobCandidatesBffRoutes.updateJobCandidateRoute(updateJobCandidateId),
      updateJobCandidateData
    );

    if (error) {
      throw error;
    }

    return data;
  }

  static async deleteJobCandidate(
    deleteJobCandidateNanoId: JobCandidateNanoId
  ) {
    const { data, error } = await this.deleteItem<{
      data: DeleteJobCandidateRequestData;
      error: string | null;
    }>(
      JobCandidatesBffRoutes.deleteJobCandidateRoute(deleteJobCandidateNanoId)
    );

    if (error) {
      throw error;
    }

    return data;
  }

  static async addCandidateToJob(
    addCandidateToJobNanoId: JobCandidateNanoId,
    addCandidateToJobData: AddCandidateToJobRequestData
  ) {
    const { data, error } = await this.postItem<
      { data: AddCandidateToJobRequestData; error: string | null },
      AddCandidateToJobRequestData
    >(
      JobCandidatesBffRoutes.addCandidateToJobRoute(addCandidateToJobNanoId),
      addCandidateToJobData
    );

    if (error) {
      throw error;
    }

    return data;
  }

  static async addCandidateToTalentPool(
    addCandidateToTalentPoolNanoId: JobCandidateNanoId,
    addCandidateToTalentPoolData: AddCandidateToTalentPoolRequestData
  ) {
    const { data, error } = await this.postItem<
      { data: AddCandidateToTalentPoolRequestData; error: string | null },
      AddCandidateToTalentPoolRequestData
    >(
      JobCandidatesBffRoutes.addCandidateToTalentPoolRoute(
        addCandidateToTalentPoolNanoId
      ),
      addCandidateToTalentPoolData
    );

    if (error) {
      throw error;
    }

    return data;
  }

  static async removeCandidateFromTalentPool(
    removeCandidateFromTalentPoolNanoId: JobCandidateNanoId,
    removeCandidateFromTalentPoolData: RemoveCandidateFromTalentPoolRequestData
  ) {
    const { data, error } = await this.postItem<
      { data: RemoveCandidateFromTalentPoolRequestData; error: string | null },
      RemoveCandidateFromTalentPoolRequestData
    >(
      JobCandidatesBffRoutes.removeCandidateFromTalentPoolRoute(
        removeCandidateFromTalentPoolNanoId
      ),
      removeCandidateFromTalentPoolData
    );

    if (error) {
      throw error;
    }

    return data;
  }

  static async recruitJobCandidate(
    recruitJobCandidateNanoId: JobCandidateNanoId | JobCandidateId,
    recruitJobCandidateData: RecruitJobCandidateRequestData
  ) {
    const { data, error } = await this.postItem<
      { data: RecruitJobCandidateRequestData; error: string | null },
      RecruitJobCandidateRequestData
    >(
      JobCandidatesBffRoutes.recruitJobCandidateRoute(
        recruitJobCandidateNanoId
      ),
      recruitJobCandidateData
    );

    if (error) {
      throw error;
    }

    return data;
  }

  static async saveJobCandidate(
    saveJobCandidateNanoId: JobCandidateNanoId | JobCandidateId,
    saveJobCandidateData: SaveJobCandidateRequestData
  ) {
    const { data, error } = await this.postItem<
      { data: SaveJobCandidateRequestData; error: string | null },
      SaveJobCandidateRequestData
    >(
      JobCandidatesBffRoutes.saveJobCandidateRoute(saveJobCandidateNanoId),
      saveJobCandidateData
    );

    if (error) {
      throw error;
    }

    return data;
  }

  static async rejectJobCandidate(
    rejectJobCandidateNanoId: JobCandidateNanoId | JobCandidateId,
    rejectJobCandidateData: RejectJobCandidateRequestData
  ) {
    const { data, error } = await this.postItem<
      { data: RejectJobCandidateRequestData; error: string | null },
      RejectJobCandidateRequestData
    >(
      JobCandidatesBffRoutes.rejectJobCandidateRoute(rejectJobCandidateNanoId),
      rejectJobCandidateData
    );

    if (error) {
      throw error;
    }

    return data;
  }

  static async revertJobCandidatePipelineStatus(
    revertJobCandidatePipelineStatusNanoId: JobCandidateNanoId | JobCandidateId
  ) {
    const { data, error } = await this.postItem<
      { data: unknown; error: string | null },
      unknown
    >(
      JobCandidatesBffRoutes.revertJobCandidatePipelineStatusRoute(
        revertJobCandidatePipelineStatusNanoId
      ),
      {}
    );

    if (error) {
      throw error;
    }

    return data;
  }

  static async importJobCandidateEmails(
    importJobCandidateEmailsNanoId: JobCandidateNanoId | JobCandidateId
  ) {
    const { data, error } = await this.postItem<
      { data: unknown; error: string | null },
      unknown
    >(
      JobCandidatesBffRoutes.importJobCandidateEmailsRoute(
        importJobCandidateEmailsNanoId
      ),
      {}
    );

    if (error) {
      throw error;
    }

    return data;
  }
}
