import {
  FetchJobJobCandidatesCacheKey,
  FetchJobJobCandidateCacheKey
} from './jobJobCandidatesTypes';

import { JobCandidateId } from '../jobCandidates/jobCandidatesTypes';

import { JobId } from '../jobs/jobsTypes';

export class JobJobCandidatesCache {
  static indexCacheKey() {
    return 'jobJobCandidates' as FetchJobJobCandidatesCacheKey;
  }

  static showCacheKey() {
    return 'jobJobCandidate' as FetchJobJobCandidateCacheKey;
  }

  static jobCandidateListCacheKey(jobCandidateId: JobCandidateId) {
    return `jobJobCandidates-${jobCandidateId}` as FetchJobJobCandidatesCacheKey;
  }

  static jobListCacheKey(jobId: JobId) {
    return `jobJobCandidates-${jobId}` as FetchJobJobCandidatesCacheKey;
  }
}
