import snakeCase from 'lodash/snakeCase';

import {
  FetchJobJobCandidatesQuery,
  JobJobCandidateFields,
  JobJobCandidateId,
  JobJobCandidateCreatedAt,
  JobJobCandidateJobId,
  JobJobCandidateJob,
  JobJobCandidateJobCandidateId,
  JobJobCandidateJobCandidate,
  JobJobCandidateUpdatedAt
} from '../jobJobCandidatesTypes';

export interface FetchJobJobCandidatesResponse {
  [JobJobCandidateFields.ID]: JobJobCandidateId;
  [JobJobCandidateFields.JOB_ID]: JobJobCandidateJobId;
  [JobJobCandidateFields.JOB]: JobJobCandidateJob;
  [JobJobCandidateFields.JOB_CANDIDATE_ID]: JobJobCandidateJobCandidateId;
  [JobJobCandidateFields.JOB_CANDIDATE]: JobJobCandidateJobCandidate;
  [JobJobCandidateFields.UPDATED_AT]: JobJobCandidateUpdatedAt;
  [JobJobCandidateFields.CREATED_AT]: JobJobCandidateCreatedAt;
}

export const fetchJobJobCandidatesColumns = [
  JobJobCandidateFields.ID,
  JobJobCandidateFields.JOB_ID,
  JobJobCandidateFields.JOB_CANDIDATE_ID,
  JobJobCandidateFields.UPDATED_AT,
  JobJobCandidateFields.CREATED_AT
];

const fetchJobJobCandidatesSBColumns = fetchJobJobCandidatesColumns.map(
  (fetchJobJobCandidatesColumn) => snakeCase(fetchJobJobCandidatesColumn)
);

export const fetchJobJobCandidatesQuery = fetchJobJobCandidatesSBColumns.join(
  ','
) as FetchJobJobCandidatesQuery;
