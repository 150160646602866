import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  StackDivider,
  Tooltip,
  useDisclosure
} from '@chakra-ui/react';
import { Heading } from '../../../../../../helpers/Heading';
import { ChevronDownIcon } from '../../../../../../icons/ChevronDownIcon';
import { NextLinkHelper } from '../../../../../../helpers/NextLinkHelper';
import { renderJobTitle } from '../../../../../jobs/utils/renderJobTitle';
import { JobLocationHelper } from '../../../../../jobs/helpers/JobLocationHelper';
import { Text } from '../../../../../../helpers/Text';
import { JobPriorityDropdown } from '../../../../../updates/components/JobPriorityDropdown';
import { JobsIcon } from '../../../../../../icons/JobsIcon';
import { renderJobEmployment } from '../../../../../jobs/utils/renderJobEmployment';
import { ClockIcon } from '../../../../../../icons/ClockIcon';
import { RelativeDateHelper } from '../../../../../../helpers/RelativeDateHelper';
import { PureButtonWithRefHelper } from '../../../../../../helpers/buttons/PureButtonWithRefHelper';
import { ViewJobCandidateDetailsJobHiringTeam } from '../../../../../jobs/pages/ViewJobCandidateDetailsPage/components/ViewJobCandidateDetailsJobHiringTeam';
import { ViewJobCandidateDetailsJobDetailsJob } from '../../../../../jobs/pages/ViewJobCandidateDetailsPage/components/ViewJobCandidateDetailsJobDetails';
import { useViewJobCandidateDetailsSubmissionContext } from '../../../../../jobs/pages/ViewJobCandidateDetailsPage/contexts/ViewJobCandidateDetailsSubmissionContext';
import { useViewJobCandidateDetailsSendAssessmentContext } from '../../../../../jobs/pages/ViewJobCandidateDetailsPage/contexts/ViewJobCandidateDetailsSendAssessmentContext';
import { IsFetched } from '../../../../../../types';
import { LoadingSkeleton } from '../../../../../../helpers/LoadingSkeleton';
import { IconButton } from '../../../../../../helpers/buttons/IconButton';
import { FilterIcon } from '../../../../../../icons/FilterIcon';
import { JobCandidateDetailsJobCandidate } from '../../../../../jobs/pages/ViewJobCandidateDetailsPage/ViewJobCandidateDetailsPage.types';
import { ViewJobCandidateModalPhoneSidebarHelper } from '../../../../../jobs/components/modals/ViewJobCandidateModal/helpers/ViewJobCandidateModalPhoneSidebarHelper';
import { ViewJobCandidateDetailsBio } from '../../../../../jobs/pages/ViewJobCandidateDetailsPage/components/ViewJobCandidateDetailsBio';
import { LinkedInIcon } from '../../../../../../icons/LinkedInIcon';
import { ViewJobCandidateDetailsComposeEmail } from '../../../../../jobs/pages/ViewJobCandidateDetailsPage/components/ViewJobCandidateDetailsComposeEmail';
import { EmailIcon } from '../../../../../../icons/EmailIcon';
import { ViewJobCandidateDetailsAddComment } from '../../../../../jobs/pages/ViewJobCandidateDetailsPage/components/ViewJobCandidateDetailsAddComment';
import { MessageIcon } from '../../../../../../icons/MessageIcon';
import { Button } from '../../../../../../helpers/Button';
import { PhoneIcon } from '../../../../../../icons/PhoneIcon';
import { DepartmentNanoId } from '../../../../../departments/departmentsTypes';
import { SourcingRoutes } from '../../../../SourcingRoutes';
import { HiringPortalSourcingRoutes } from '../../../../../hiringPortal/HirngPortalSourcingRoutes';
import { JobNanoId } from '../../../../../jobs/jobsTypes';
import { ViewJobCandidateDetailsHeaderMenuJobs } from '../../../../../jobs/pages/ViewJobCandidateDetailsPage/components/ViewJobCandidateDetailsHeaderMenuJobs';

interface SourcingTalentProfileHeaderMenuProps {
  jobCandidate: JobCandidateDetailsJobCandidate;
  hasScrolled: boolean;
  requiresCredit: boolean;
  job: ViewJobCandidateDetailsJobDetailsJob;
  departmentNanoId: DepartmentNanoId;
  jobIsFetched: IsFetched;
  isHiringPortalPage?: boolean;
  totalProspects?: number;
}

export default function SourcingTalentProfileHeaderMenu({
  jobCandidate,
  hasScrolled,
  requiresCredit,
  job,
  jobIsFetched,
  totalProspects,
  isHiringPortalPage,
  departmentNanoId
}: SourcingTalentProfileHeaderMenuProps) {
  const { isCandidateSubmissionOpen } =
    useViewJobCandidateDetailsSubmissionContext();

  const { isSendEmailAssessmentOpen } =
    useViewJobCandidateDetailsSendAssessmentContext();

  const isRightSidebarOpen =
    isCandidateSubmissionOpen || isSendEmailAssessmentOpen;

  const {
    isOpen: isOpenPhonrCallActivity,
    onOpen: onOpenPhonrCallActivity,
    onToggle: onTogglePhonrCallActivity
  } = useDisclosure();

  const jobDetailsRoute = (jobNanoId: JobNanoId) => {
    return isHiringPortalPage
      ? HiringPortalSourcingRoutes.viewJobDetails(
          departmentNanoId,
          jobCandidate.nanoId,
          jobNanoId
        )
      : SourcingRoutes.viewJobDetails(
          departmentNanoId,
          jobCandidate.nanoId,
          jobNanoId
        );
  };

  return (
    <>
      <Flex
        h={14}
        top={0}
        gap={6}
        zIndex={89}
        position="sticky"
        overflow="hidden"
        bg={isRightSidebarOpen ? 'gray.50' : 'white'}
        pl={isRightSidebarOpen ? { base: 4, xl: 12 } : 4}
        pr={4}
      >
        {isRightSidebarOpen ? (
          <Flex alignItems="center">
            <Heading level="h3">
              Candidate {isCandidateSubmissionOpen && 'Submission'}{' '}
              {isSendEmailAssessmentOpen && 'Assessment'}
            </Heading>
          </Flex>
        ) : (
          <>
            <Flex
              top={0}
              flexDir="column"
              position="relative"
              transitionDuration="slow"
              w={{ base: '274px', xl: '304px' }}
            >
              <Flex
                flex={1}
                alignItems="center"
                borderRight="1px solid"
                borderColor="gray.200"
                gap={2}
                pr={3}
              >
                <Heading level="h2" color="gray.900" noOfLines={1}>
                  Prospects
                </Heading>
                <Heading level="h4" color="gray.900">
                  {totalProspects}
                </Heading>

                <IconButton
                  border="none"
                  boxShadow="none"
                  hierarchy="tertiary"
                  aria-label="filter"
                  ml="auto"
                  h={8}
                  w={8}
                  minW="unset"
                  icon={<FilterIcon w={4} h={4} />}
                />
              </Flex>
            </Flex>

            <Flex
              h={28}
              top={0}
              flex={1}
              flexDir="column"
              position="relative"
              transitionDuration="slow"
              transform={hasScrolled ? 'translate(0, -50%)' : undefined}
            >
              <Flex
                gap={4}
                opacity={hasScrolled ? 0 : 1}
                w="100%"
                flex={1}
                alignItems="center"
                flexWrap="nowrap"
              >
                <LoadingSkeleton count={1} loaded={jobIsFetched}>
                  {job ? (
                    <HStack
                      spacing={4}
                      divider={<StackDivider />}
                      flexWrap="nowrap"
                    >
                      <Tooltip
                        label={renderJobTitle(job)}
                        aria-label="Name"
                        placement="top"
                      >
                        <span>
                          <ViewJobCandidateDetailsHeaderMenuJobs
                            jobCandidate={jobCandidate}
                            job={job}
                            jobDetailsRoute={jobDetailsRoute}
                          />
                        </span>
                      </Tooltip>

                      <Flex gap={2} alignItems="center" shrink={0}>
                        <Text>Job Priority</Text>
                        <JobPriorityDropdown priority="high" />
                      </Flex>

                      <JobLocationHelper job={job} withIcon />

                      <HStack spacing={2} sx={{ flexShrink: '0' }}>
                        <JobsIcon w="20px" h="20px" color="gray.500" />

                        <Text>
                          {renderJobEmployment(job.employment) || '-'}
                        </Text>
                      </HStack>

                      <HStack spacing={2} sx={{ flexShrink: '0' }}>
                        <ClockIcon w="20px" h="20px" color="gray.500" />

                        <Text>
                          <RelativeDateHelper date={job.createdAt as string} />
                        </Text>
                      </HStack>

                      <NextLinkHelper
                        href={jobDetailsRoute(job.nanoId)}
                        passHref
                      >
                        <PureButtonWithRefHelper
                          as="a"
                          size="medium"
                          hierarchy="link"
                          i18nText="Job Details"
                          _hover={{ textDecoration: 'none' }}
                          sx={{ flexShrink: '0' }}
                        />
                      </NextLinkHelper>

                      <ViewJobCandidateDetailsJobHiringTeam job={job} />
                    </HStack>
                  ) : null}
                </LoadingSkeleton>
              </Flex>

              <Flex
                h={14}
                gap={2}
                alignItems="center"
                top={hasScrolled ? 0 : '100%'}
                transitionDuration="slow"
                zIndex={999}
              >
                <HStack spacing={4} divider={<StackDivider />}>
                  <HStack spacing={2}>
                    {jobCandidate && job ? (
                      <ViewJobCandidateDetailsBio
                        requiresCredit={requiresCredit}
                        jobCandidate={jobCandidate}
                        isCollapsed
                        job={job}
                        isHiringPortalPath={isHiringPortalPage}
                      />
                    ) : null}

                    <Flex
                      w={6}
                      h={6}
                      bg="#069"
                      alignItems="center"
                      borderRadius="full"
                      justifyContent="center"
                    >
                      <LinkedInIcon borderRadius="full" w={3.5} h={3.5} />
                    </Flex>
                  </HStack>

                  {job ? (
                    <HStack spacing={4}>
                      <Tooltip
                        label={renderJobTitle(job)}
                        aria-label="Name"
                        placement="top"
                      >
                        <span>
                          <ViewJobCandidateDetailsHeaderMenuJobs
                            jobCandidate={jobCandidate}
                            job={job}
                            jobDetailsRoute={jobDetailsRoute}
                          />
                        </span>
                      </Tooltip>

                      <JobLocationHelper job={job} withIcon />
                    </HStack>
                  ) : null}
                </HStack>

                <Flex gap={3} alignItems="center" ml="auto">
                  <ViewJobCandidateDetailsComposeEmail
                    trigger={
                      <Tooltip
                        label="Email"
                        textTransform="capitalize"
                        hasArrow
                      >
                        <IconButton
                          h={6}
                          w={6}
                          minW={0}
                          minH={0}
                          value="email"
                          display="flex"
                          aria-label=""
                          alignItems="center"
                          borderRadius="full"
                          justifyContent="center"
                          icon={<EmailIcon w={3.5} h={3.5} />}
                          hierarchy="tertiary"
                        />
                      </Tooltip>
                    }
                  />

                  <ViewJobCandidateDetailsAddComment
                    trigger={
                      <Tooltip
                        label="Comment"
                        textTransform="capitalize"
                        hasArrow
                      >
                        <IconButton
                          h={6}
                          w={6}
                          minW={0}
                          minH={0}
                          value="comment"
                          display="flex"
                          aria-label=""
                          alignItems="center"
                          borderRadius="full"
                          justifyContent="center"
                          icon={<MessageIcon w={3.5} h={3.5} />}
                          hierarchy="tertiary"
                        />
                      </Tooltip>
                    }
                  />

                  <HStack
                    spacing={0}
                    overflow="hidden"
                    borderRadius="base"
                    divider={<StackDivider borderColor="primary.100" />}
                  >
                    <Button
                      h={7}
                      px={4}
                      size="extra-small"
                      borderRadius="none"
                      onClick={onOpenPhonrCallActivity}
                      leftIcon={<PhoneIcon w={3} h={3} fill="white" />}
                    >
                      Call
                    </Button>

                    <IconButton
                      h={7}
                      display="flex"
                      aria-label=""
                      size="extra-small"
                      alignItems="center"
                      borderRadius="none"
                      justifyContent="center"
                      icon={<ChevronDownIcon w={3.5} h={3.5} fill="white" />}
                    />
                  </HStack>
                </Flex>
              </Flex>
            </Flex>
          </>
        )}
      </Flex>

      <Drawer
        size="md"
        isOpen={isOpenPhonrCallActivity}
        onClose={onTogglePhonrCallActivity}
      >
        <DrawerOverlay />
        <DrawerContent p={0}>
          <DrawerBody p={0}>
            {jobCandidate ? (
              <ViewJobCandidateModalPhoneSidebarHelper
                fullWidth
                withoutBorder
                jobCandidate={jobCandidate}
                toggleFollowUpEmail={console.log}
                isSidebarOpen={isOpenPhonrCallActivity}
                sidebarToggle={onTogglePhonrCallActivity}
                personalPhoneNumber={jobCandidate.phone}
                companyPhoneNumber={jobCandidate.companyPhone}
              />
            ) : null}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
